import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sa-footer-horizontal',
  templateUrl: './footer-horizontal.component.html',
  styleUrls: ['./footer-horizontal.component.scss']
})
export class FooterHorizontalComponent implements OnInit {

  @Input() footerItemList = [
    {label:'Faqs', link:'/faq'},
    {label:'Acceptable Use', link:'/acceptable-use-policy'},
    {label:'Policy Accessibility', link:'/accessibility'},
    {label:'Privacy Policy', link:'/privacy-policy'},
    {label:'Terms & Conditions', link:'/terms-and-conditions'},
    {label:'Terms of Use', link:'terms-of-use'},
    {label:'Modern Slavery Policy', link:''}
  ]

  constructor() { }

  ngOnInit(): void {
  }

  public isExternal(link): boolean {
    const pat = /^https?:\/\//i;
    return pat.test(link);
  }

}
