import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BrowseComponent } from './browse.component';
import { CardsModule } from '../cards/cards.module';

@NgModule({
  declarations: [ 
    BrowseComponent
    ],
  imports: [
    RouterModule,
    CommonModule,
    LazyLoadImageModule,
    CardsModule
  ],
  exports: [
    BrowseComponent
  ]
})
export class BrowseLibModule {
 
}
