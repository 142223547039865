<ul>
  <li *ngFor="let accordionData of data; let idx = index;"
      [ngClass]="{'active': accordionData.selected}"
      class="content-item">

    <p *ngIf="!accordionData.categories || (accordionData.categories && accordionData.categories.length === 0)"
       class="no-child"
       (click)="onItemClick(accordionData.categoryLabel)">
      {{accordionData.categoryLabel}}
    </p>

    <p *ngIf="accordionData.categories && accordionData.categories.length === 1"
       class="no-child single-child"
       (click)="onItemClick(accordionData.categories[0])">
      {{accordionData.categoryLabel}}
    </p>

    <p *ngIf="accordionData.categories && accordionData.categories.length > 1"
       class="accordion"
       (click)="toggleAccordion($event, accordionData)">
      {{accordionData.categoryLabel}}
    </p>
    <ul *ngIf="accordionData.categories && accordionData.categories.length > 1"
        class="content accordion-content">
      <li *ngFor="let category of accordionData.categories"
          (click)="onItemClick(category)"
          class="panel">
        <p>{{category.competitionLabel}}</p>
      </li>
    </ul>
  </li>
</ul>
