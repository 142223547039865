<div [class.live]="isAnyFixtureLive()" class="container-card-fixture-min">
  <a [attr.href]="link" [class.disabled]="!isMediaLive('video')" class="anchor"></a>

  <div *ngIf="labelLiveTag && isAnyFixtureLive()" class="live-tag">
    <span [innerText]="labelLiveTag"></span>
  </div>

  <div class="team-type" *ngIf="fixtureData.teamType?.name" [textContent]="fixtureData.teamType.name"></div>


  <div class="event" *ngIf="isTypeEvent()">

    <div *ngIf="isThumbnailAvailable()" [lazyLoad]="fixtureData.thumbnailFlavors['640']"
         class="thumbnail lazyload-image">
    </div>

    <h2 class="title" [innerText]="getName()">
    </h2>

    <h3 class="description" [innerText]="getDescription()">
    </h3>

    <div class="content-date-location">
      <ng-template [ngTemplateOutlet]="dateAndStadium"></ng-template>
      <div *ngIf="labelLiveTag && isAnyFixtureLive()" class="watch-now"
           [textContent]="'watch-live' | commonLabel | async"></div>
    </div>

  </div>

  <div class="teams" *ngIf="!isTypeEvent()">
    <div class="team team-home ">
      <div *ngIf="getHomeTeamData().logo" [lazyLoad]="getHomeTeamData().logoFlavors['100']"
           class="badge lazyload-image">
      </div>
      <label [textContent]="getHomeTeamData().name" class="team-name"></label>
    </div>

    <div class="content-date-location">
      <div *ngIf="isCompetition" [textContent]="getCompetition().name" class="competition"></div>
      <ng-template [ngTemplateOutlet]="dateAndStadium"></ng-template>
      <div *ngIf="labelLiveTag && isAnyFixtureLive()" class="watch-now"
           [textContent]="'watch-live' | commonLabel | async"></div>
    </div>

    <div class="team team-away ">
      <div *ngIf="getAwayTeamData().logo" [lazyLoad]="getAwayTeamData().logoFlavors['100']"
           class="badge lazyload-image">
      </div>
      <label [textContent]="getAwayTeamData().name" class="team-name"></label>
    </div>
  </div>
</div>

<ng-template #dateAndStadium>
  <div *ngIf="isDate" class="date-content ">
    <div class="content-time">
      <p [textContent]="getTime()" class="time"></p>
      <p class="date">{{date}}</p>
    </div>
    <p [textContent]="getStadiumName()" class="location"></p>
  </div>
</ng-template>
