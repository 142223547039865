<div class="gdpr container" [@slideInOut]="animationState">
  <div class="content row p-0">
    <div class="col-12 col-md-11 p-0">
      <div class="row m-0 p-0">
        <div class="col-12 col-md-8">
          <h3>
            We use cookies on this site to enhance your user experience.
          </h3>
          <p>
            By clicking any link on this page you are giving your consent for us
            to set cookies
          </p>
        </div>
        <div class="col-12 col-md-4">
          <a
            class="inline link-text"
            [routerLink]="'/' + policy_link"
            href="'/'+policy_link"
            rel="noreferrer"
          >
            More Information
          </a>
          <button class="inline" (click)="setGdpr()">I Accept</button>
        </div>
      </div>
    </div>
  </div>
  <span class="close" (click)="animationState = 'out'">x</span>
</div>
