import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {AccordionData, CategoryData} from './accordion.type';

@Component({
  selector: 'sa-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionComponent {
  @Input() data: AccordionData[];
  @Output() triggerItemClick: EventEmitter<CategoryData | string>;

  private previousSelectedAccordion: AccordionData;

  constructor() {
    this.previousSelectedAccordion = null;
    this.triggerItemClick = new EventEmitter<CategoryData>();
    this.data = [];
  }

  public toggleAccordion(event: Event, accordion: AccordionData): void {


    if (this.previousSelectedAccordion && accordion !== this.previousSelectedAccordion) {
      this.previousSelectedAccordion.selected = false;
    }
    accordion.selected = !accordion.selected;
    this.previousSelectedAccordion = accordion;


    if (accordion.selected) {
      setTimeout(() => {
        // @ts-ignore
        event.target.closest('.ng-dropdown-panel-items').scrollTop = event.target.offsetTop;
      }, 10);
    }
  }

  onItemClick(item: CategoryData | string): void {
    this.triggerItemClick.emit(item);
  }
}
