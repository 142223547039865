import { environment } from '../../../../../../src/environments/environment';
import { Injectable } from '@angular/core';
import { GenericPaymentResponse, Init } from '../../models/userAuth/user-auth.type';
import { UserAuth } from './userAuth';
import { HttpClient } from '@angular/common/http';
import { configs } from '../../../../../../src/app/core/configs';
import { UrlUtils } from '../../../../../../src/app/utils/url-utils';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';

// @dynamic
@Injectable()
export class UserAuthSsoService extends UserAuth {


  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
  ) {
    super();
  }

  public streamPaymentsInit(data: Init, callback?: (response: GenericPaymentResponse) => void): void {

    if (!data.jwtToken) {

      const streamPaymentSessionData: GenericPaymentResponse = window.localStorage.getItem('StreamPaymentSessionData')
        ? JSON.parse(window.localStorage.getItem('StreamPaymentSessionData'))
        : null;

      if (streamPaymentSessionData) {
        data.jwtToken = streamPaymentSessionData.JwtTokenCacheKey;
      }
    }

    if (data.jwtToken) {

      this.streamPayments.init(data);
      super.streamPaymentsSetSessionState(null, (response) => {

        if (callback) {
          callback(response);
        }
      });
    } else if (callback) {
      callback(null);
    }
  }

  public streamPaymentsLogOut(options, callback: (res: GenericPaymentResponse) => void): void {
    options = options || {};
    options.apisessionid = this.apisessionid;
    super.streamPaymentsLogOut(options, callback);
    this.streamPayments.init({jwtToken: null});
  }

  public loginAndRefresh(): Observable<boolean> {
    const dataEmitter = new ReplaySubject<boolean>(1);
    let jwtToken: string;
    const urlParams = UrlUtils.extractUrlParamsFromExistingUrl(window.location.href);
    // login
    if (urlParams.token) {

      const {token: token, ...queryParams} = urlParams;

      jwtToken = token;

      this.streamPaymentsInit({
        jwtToken: jwtToken,
        language: 'de',
      }, (response) => {

        if (response.CurrentCustomerSession !== null) {
          // store token

          this.http.post(environment.sso.middleware + configs.sso.middleware.storeToken, {token: jwtToken}).subscribe((result: any) => {
            dataEmitter.next(true);
            this.router.navigate([], {relativeTo: this.activatedRoute, queryParams});
          });
        } else {
          dataEmitter.next(true);
        }

      });

    } else {

      this.streamPaymentsInit({
        language: 'de',
      }, (response) => {

        if (response && response?.JwtTokenCacheKey !== null) { // response?.CurrentCustomerSession === null &&
          // refresh token

          this.http.post(environment.sso.middleware + configs.sso.middleware.refreshToken, {token: response.JwtTokenCacheKey}).subscribe((result: any) => {

            if (result?.jwt) {
              this.streamPaymentsInit({
                jwtToken: result.jwt,
                language: 'de',
              }, (response) => {

                //  console.log(response);
                dataEmitter.next(true);
              });
            } else {
              dataEmitter.next(true);
            }
          }, error => {
            dataEmitter.next(true);
            console.error(error);
          });
        }
      });
    }

    return dataEmitter.asObservable();
  }
}
