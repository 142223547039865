import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';

export interface TeamLandingPageData {
 page: string; group: string; item: string;
}

@Injectable({ providedIn: 'root' })
export class TeamLandingPageResolver implements Resolve<TeamLandingPageData> {
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): { page: string; group: string; item: string; } {
    return {
      page: route.url[0].path,
      group: route.url[1] ? route.url[1].path : null,
      item: route.url[2] ? route.url[2].path : null
    };
  }
}
