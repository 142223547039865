import {Component, OnInit, Input} from '@angular/core';
import {formatDate} from '@angular/common';
import {AnywhereItemData} from '../../../models/anywhere/anywhere-api.type';
import {PlayerUtils} from '../../../utils/player-utils';
import {VideoEntitlementEnum} from '../../../models/playlist/video.type';
import {GetMetaData} from '../../../models/cards/cards.type';

@Component({
  selector: 'sa-card-video',
  templateUrl: './card-video.component.html'
})
export class CardVideoComponent implements OnInit {
  defaultImage = '/assets/images/placeholder.jpg';
  entitlementKey: string;

  @Input() componentData: AnywhereItemData<unknown>;
  @Input() getMetaData: GetMetaData<unknown>;

  @Input() playlistPath: string;
  @Input() className = '';

  @Input() isCategory: boolean;
  @Input() isRuntime: boolean;
  @Input() isEntitlement: boolean;
  @Input() isDateTimeImage: boolean;
  @Input() isDateTime: boolean;
  @Input() isSubTitle: boolean;

  @Input() categories: string;
  @Input() runtime: string;
  @Input() title: string;
  @Input() subTitle: string;
  @Input() thumbnail: string;
  @Input() entitlement: string;
  @Input() date: string;
  @Input() formatDate: string = 'd LLL y | HH:mm';
  @Input() link: string;
  linkImg: string = '';
  @Input() typeCarousel: string;

  constructor() {
  }

  ngOnInit() {


    if (this.componentData) {
      this.link = `/video/${this.generatePath()}`;
      this.date = formatDate(this.componentData.publicationData.releaseFrom, this.formatDate, 'de');
      this.entitlement = VideoEntitlementEnum['*'];
      this.entitlementKey = '*';

      if (this.componentData.mediaData.thumbnailUrl.length > 0) {
        this.linkImg = this.thumbnail = `${this.componentData.mediaData.thumbnailUrl}width/1000`;
      } else {
        this.linkImg = this.defaultImage;
      }

      if (this.getMetaData) {
        const data = this.getMetaData(this.componentData);

        if (data.title) {
          this.title = data.title;
        }

        if (data.subTitle) {
          this.subTitle = data.subTitle;
        }

        if (data.categories) {
          this.categories = data.categories;
        }

        if (data.entitlements) {
          this.entitlement = data.entitlements.value;
          this.entitlementKey = data.entitlements.key;
        }

        if (data.runtime) {
          this.runtime = data.runtime;
        }
      }
    }
  }

  private generatePath(): string {

      return PlayerUtils.encodeUrlParamsForPlayer(`${this.componentData.mediaData.entryId}|${this.playlistPath}`);
  }
}
