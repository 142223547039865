<ng-container *ngIf="boxsetsData">
    <h2 *ngIf="isTitleBoxsets" class="title" [textContent]="boxsetsData.title"></h2>
    <h4 *ngIf="isSubtitleBoxsets" class="subtitle" [textContent]="boxsetsData.subtitle"></h4>

    <div class="boxsets-container">
      <div class="boxset-container" *ngFor="let boxset of boxsetsData.boxsets; let blockIndex = index"
           [attr.data-n-items]="boxset.number_of_items" [attr.data-type]="boxset.type ? boxset.type.type : null">

        <ng-container *ngFor="let item of boxset.items; let i = index">
          <div class="item-container">
            <div *ngIf="i < boxset.number_of_items" class="item lazyload-image" [defaultImage]="defaultImage"
                 [lazyLoad]="item.image.sizes.medium.url">
              <a  routerLinkActive="selected" [routerLink]="item.feed ? getLink(blockIndex, i) : '#'">
                <h3 class="item-title" [textContent]="item.title"></h3>
              </a>
            </div>
          </div>
        </ng-container>

      </div>
    </div>
  </ng-container>
