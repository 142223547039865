import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FilterDropdownComponent } from './filter-dropdown/filter-dropdown.component';
import { FilterDurationComponent } from './filter-duration/filter-duration.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { FilterGroupingComponent } from './filter-grouping/filter-grouping.component';
import { AccordionModule } from '../accordion/accordion.module'

@NgModule({
  declarations: [ 
    FilterDropdownComponent,
    FilterDurationComponent,
    FilterGroupingComponent
    ],
  imports: [
    RouterModule,
    CommonModule,
    NgSelectModule,
    FormsModule,
    AccordionModule
  ],
  exports: [
    FilterDropdownComponent,
    FilterDurationComponent,
    FilterGroupingComponent
  ]
})
export class FilterLibModule {
 
}
