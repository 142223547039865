import { ChangeDetectorRef } from '@angular/core';
import { VideoPlayerConfig } from '../../models/video/video-player-config.type';
import { Router } from '@angular/router';
import { UserAuthSsoService } from '../../services/user-auth/user-auth-sso.service';
import { KalturaPlayerEvents } from '../../models/video/kaltura.enum';
import { AnywhereItemData } from '../../models/anywhere/anywhere-api.type';
import { AnywhereMetaData } from '../../../../../../src/app/core/models/anywhere-metadata.type';
import { AmgFixture } from '../../models/fixture/amg.fixture';

export enum VideoStatusEnum {
  canPlay = 'canPlay',
  doRegister = 'doRegister',
  doSubscription = 'doSubscription',
  noContent = 'noContent',
  noEntitlement = 'noEntitlement'
}

export interface VideoErrorTexts {
  doRegister: string;
  doSubscription: string;
  noContent: string;
  noEntitlement: string;
}

export class VideoBaseComponent {
  public videoPlayerStatus: VideoStatusEnum;
  public playerKalturaEvents: Set<KalturaPlayerEvents>;
  public videoErrorTexts: VideoErrorTexts = {
    doRegister: 'NOT LOGGED',
    doSubscription: 'NOT REGISTERED',
    noContent: 'AN ERROR HAS OCCURRED',
    noEntitlement: 'NO ENTITLEMENT',
  };
  protected isVideoPlayerInitialized: boolean;
  public loadPlayer: boolean;

  constructor(
    protected userAuthService: UserAuthSsoService,
    protected cd: ChangeDetectorRef,
    protected router: Router,
    protected uiConfId: string,
    public videoPlayerConfig: VideoPlayerConfig,
  ) {
    this.isVideoPlayerInitialized = false;
    this.playerKalturaEvents = new Set<KalturaPlayerEvents>([
      KalturaPlayerEvents.playerReady,
      KalturaPlayerEvents.playerPlayEnd,
    ]);
  }


  protected setVideoPlayerConfig(entryId: string): void {
    this.loadPlayer = false;
    this.cd.detectChanges();

    //  authorisation and subscriptions

    // this.userAuthService.loginAndRefresh().subscribe(() => {
    this.userAuthService.streamPaymentsGetKSession({entryId}, ksResponse => {
      if (ksResponse) {

        switch (ksResponse.Status) {
          case -1: {
            // can play

            const flashvars = this.videoPlayerConfig.flashvars ? this.videoPlayerConfig.flashvars : {};

            if (ksResponse.KSession) {
              flashvars.ks = ksResponse.KSession; // Overwrite KS
            }

            const videoPlayerConfig = {
              ...this.videoPlayerConfig,
              flashvars,
              entryId,
              uiConfId: this.uiConfId,
            };

            this.loadPlayer = true;
            this.cd.markForCheck();

            this.videoPlayerConfig = videoPlayerConfig;
            this.videoPlayerStatus = VideoStatusEnum.canPlay;
            break;
          }
          case 0: {
            // register
            this.videoPlayerStatus = VideoStatusEnum.doRegister;
            break;
          }
          case 1: {
            // subscribe
            this.videoPlayerStatus = VideoStatusEnum.doSubscription;
            break;
          }
          default: {
            // error
            // TODO: an unhandled error doesn't mean no content. If a user has a connection error then you don't tell him that
            //  there is no content, but instead an appropriate error.
            this.videoPlayerStatus = VideoStatusEnum.noContent;

            if (ksResponse.Status === 2) {
              // NoEntitlement
              this.videoPlayerStatus = VideoStatusEnum.noEntitlement;
            }
          }
        }
      } else {
        this.videoPlayerStatus = VideoStatusEnum.noContent;
      }

      // TODO: video player can be init before it can play
      this.isVideoPlayerInitialized = (this.videoPlayerStatus === VideoStatusEnum.canPlay);

      this.cd.markForCheck();
    });

    // });


  }

  protected setDoubleclickParams(card: AnywhereItemData<AnywhereMetaData>, streamPlayItem?: AmgFixture): void {
    let player_width = '0';
    let player_height = '0';
    let category = '';
    let video_title;
    let video_tags = '';
    let video_id;

    if (card) {
      category = encodeURI([...(card.metaData?.national_team ? card.metaData?.national_team : []), ...(card.metaData?.ofb ? card.metaData?.ofb : []), ...(card.metaData?.ligen_and_bewerbe ? card.metaData?.ligen_and_bewerbe : [])].join(','));
      video_title = encodeURI(card.metaData?.title);
      video_tags = encodeURI((card.metaData?.tags ? card.metaData?.tags : []).join(','));
      video_id = card.mediaData.entryId;

    } else {
      video_id = streamPlayItem.mediaData[0].entryId;
      video_title = encodeURI(streamPlayItem.name);
    }

    const cachebuster: string = new Date().getTime().toString();


    let member = '';
    this.userAuthService.userStateObservable.subscribe(userState => {
      if (userState?.details?.CurrentCustomerSession?.CustomerId) {

        member = userState?.details?.CurrentCustomerSession?.CustomerId;
      }
    });


    //TODO doesn't work like that. requires css changes to have the div wrapper with the video size check Matchroom (vast)
    // let videoElement = document.querySelector('sa-video-player');
    // if (videoElement) {
    //   player_height = videoElement.clientHeight.toString();
    //   player_width = videoElement.clientWidth.toString();
    // }

    //const adTagUrl = `https://pubads.g.doubleclick.net/gampad/ads?iu=/22066919137/oefb_video&description_url=https%3A%2F%2Fwww.oefb.at%2F&npa=0&sz=640x480&cust_params=member%3D${member}%26video_title%3D${video_title}%26video_tags%3D${video_tags}%26video_id%3D${video_id}%26player_width%3D${player_width}%26player_height%3D${player_height}%26category%3D${category}&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=${cachebuster}`;
    const adTagUrl = `https://pubads.g.doubleclick.net/gampad/ads?iu=/22066919137/oefb_video_postroll&description_url=https%3A%2F%2Fwww.oefb.at%2F&npa=0&sz=640x480&cust_params=member%3D${member}%26video_title%3D${video_title}%26video_tags%3D${video_tags}%26video_id%3D${video_id}%26player_width%3D${player_width}%26player_height%3D${player_height}%26category%3D${category}&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=${cachebuster}`;

    this.videoPlayerConfig.flashvars.doubleclick = {
      adTagUrl,
    };
    this.cd.markForCheck();
  }
}
