export const configs = {

  sso: {
    paths: {
      login: '/steward/token/portal',
      logout: '/steward/jwt/logout',
      register: '/oefb/Ballkontakt#register',
      profile: '/oefb/Ballkontakt#abo'
    },

    middleware: {
      storeToken: '/storeToken',
      refreshToken: '/refreshAccessToken'
    }
  },


  cloudMatrix: {
    url: 'https://austrianfa-cm.streamamg.com/'
  },

  kaltura: {
    kalturaBaseUrl: 'https://open.http.mp.streamamg.com',
    partnerId: '3001396',
    uiConfId: '30030153', // v2.55 30028450
    flashvars: {
      autoPlay: false,
      // autoMute: false,
      // IframeCustomPluginJs1: '/assets/kaltura-scripts/player-custom-script.js',
      IframeCustomPluginCss1: '/assets/css/video-player.css'
    }
  },
};

