

<div class="footer-list">
    <div class="content-list">
        <div *ngFor="let item of footerItemList">
            <ng-container *ngIf="isExternal(item.link); else internal">
                <a [attr.href]="item.link" [textContent]="item.label"></a>
            </ng-container>
            <ng-template #internal>
                <a [routerLink]="item.link" [textContent]="item.label"></a>
            </ng-template>
        </div>
    </div>
</div>