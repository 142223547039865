import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { WordpressService } from 'wordpress-lib';
import { SubSink } from 'subsink';
import { FixtureFilters, UserAuthSsoService, UserState } from 'components-lib';
import { environment } from '../environments/environment';
import { FixtureLiveService } from './core/services/fixture-live.service';
import { filter } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

const REFRESH_AUTHENTICATION_INTERVAL = 1000 * 60 * 10; // 10 minutes

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  // private commonDataSubscription: Subscription;
  private subSink = new SubSink();
  private commonDataUrl: string = '';
  buttonName: string = "-";
  isSidebarImage = true;
  listItems = [];
  private filters: FixtureFilters;
  public isLoggedIn: boolean;
  errorServer = '';
  @Output() onSubmit = new EventEmitter<any>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private wordpressService: WordpressService,
    private userAuthSsoService: UserAuthSsoService,
    private fixtureLiveService: FixtureLiveService,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef) {

    this.isLoggedIn = false;
    this.filters = {};
    this.commonDataUrl = `${environment.wordpress.api}${environment.wordpress.commonData}`;
  }

  ngOnInit(): void {

    this.userAuthSsoService.loginAndRefresh();
    window.setInterval(() => this.userAuthSsoService.loginAndRefresh(), REFRESH_AUTHENTICATION_INTERVAL);


    let currentRoute = '';

    this.router.events
      .pipe(filter(event => event instanceof NavigationStart || event instanceof NavigationEnd))
      .subscribe((evt: NavigationStart | NavigationEnd) => {
        if (evt instanceof NavigationStart) {
          if (!currentRoute.startsWith('oefbthek')) {
            window.scrollTo(0, 0)
          }
        } else {
          currentRoute = evt.urlAfterRedirects
            .split('/')[1] // Route
            .split('?')[0] // Remove params
            .split('#')[0]; // Remove anchor
        }
      });

    this.subSink.add(this.fixtureLiveService.getLiveFixtures().liveCount
      .subscribe(count => {
        if (count > 0) {
          this.renderer.addClass(document.body.parentElement, 'live-on');
        } else {
          this.renderer.removeClass(document.body.parentElement, 'live-on');
        }
      }));

    this.getCommonData();

    this.userAuthSsoService.userStateObservable.subscribe(userState => {
      if (userState) {
        this.isLoggedIn = userState.state !== UserState.notLoggedIn;

        if (this.isLoggedIn) {
          this.renderer.addClass(this.document.body.parentElement, 'logged-in');
        } else {
          this.renderer.removeClass(this.document.body.parentElement, 'logged-in');
        }
      }
    });
  }

  getCommonData() {
    this.subSink.add(this.wordpressService.getWordpressData<any>(this.commonDataUrl)
      .subscribe(data => {
        if (data) {
          this.listItems = data.sidebar_items;
          this.cd.markForCheck();
        }
      }));
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

}
