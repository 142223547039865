export class CharacterUtils {
  public static parseLabel(label: string): string {
    return encodeURI(label.toLowerCase()
      .replace(/[\s]+/g, '-')
      .replace(/[ßüöä]/g,  ((substring, tags) => {

        switch (substring) {
          case 'ß': return 'ss';
          case 'ü': return 'u';
          case 'ö': return 'o';
          case 'ä': return 'a';
        }

        return '';
      })));
  }
}
