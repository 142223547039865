

 <div *ngIf="isDropdownTitle" class="title-dropdown">
    <h3 class="title" [textContent]="dropdownTitle"></h3>
</div>
<ng-select 
    [placeholder] = "placeholder"
    [items] = "items"
    [(ngModel)] = "selectedItem"
    (ngModelChange) = "onFilterChange($event)"
    [searchable] = "searchable"
    [disabled] = "disabled"
    [clearable] = "clearable"
    >
</ng-select>
