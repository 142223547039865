import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';

import {AnywhereDataResponse, AnywherePaginatedData} from '../models/anywhere/anywhere.type';
import {
  AnywhereData,
  AnywherePagingData,
  AnywhereResponse,
  AnywhereSectionData
} from '../models/anywhere/anywhere-api.type';
import {AnywhereUtils} from '../utils/anywhere-utils';
import {catchError, map} from 'rxjs/operators';
import {configs} from '../../../../../src/app/core/configs';

// @dynamic
@Injectable()
export class AnywhereService {
  public static getItemData<AnywhereMetaData>(data: AnywhereResponse<AnywhereMetaData>): AnywherePaginatedData<AnywhereMetaData> {
    if ((data as AnywhereSectionData<AnywhereMetaData>).sections) {
      const anywhereData = (data as AnywhereSectionData<AnywhereMetaData>);
      return {
        id: anywhereData.sections[0].id,
        name: anywhereData.sections[0].name,
        itemData: anywhereData.sections[0].itemData,
        pagingData: anywhereData.sections[0].pagingData
      };
    } else if ((data as AnywhereData<AnywhereMetaData>).itemData) {
      const anywhereData = (data as AnywhereData<AnywhereMetaData>);
      return {
        id: anywhereData.id,
        name: anywhereData.name,
        itemData: anywhereData.itemData,
        pagingData: anywhereData.pagingData
      };
    }

    return null;
  }

  constructor(private httpClient: HttpClient) {
  }

  public getData<AnywhereMetaData>(
    url: string,
    filterData?: { [key: string]: (string | { [key: string]: string }) },
    pagingData?: AnywherePagingData,
    defaultPageSize?: string): Observable<AnywhereDataResponse<AnywhereMetaData>> {

    if (url.startsWith(configs.cloudMatrix.url)) {

      let anywhereUrl = '';

      if (pagingData) {
        if (AnywhereUtils.isMoreDataAvailable(pagingData)) {
          anywhereUrl = AnywhereUtils.generateAnywhereUrl(url, this.generateAnywhereSearchQuery(filterData), defaultPageSize, ++pagingData.pageIndex);
        } else {
          return of({noMoreData: true});
        }
      } else {
        anywhereUrl = AnywhereUtils.generateAnywhereUrl(url, this.generateAnywhereSearchQuery(filterData), defaultPageSize);
      }

      return this.httpClient.get<AnywhereResponse<AnywhereMetaData>>(anywhereUrl)
        .pipe(
          map<AnywhereResponse<AnywhereMetaData>, AnywhereDataResponse<AnywhereMetaData>>(anywhereResponse => {
            if (anywhereResponse && ((anywhereResponse as AnywhereSectionData<AnywhereMetaData>).sections || (anywhereResponse as AnywhereData<AnywhereMetaData>).itemData)) {
              const itemData = AnywhereService.getItemData(anywhereResponse);

              if (itemData) {
                return itemData;
              }
            }

            return {error: true};
          }),
          catchError(err => of({error: true}))
        );
    } else {

      return new Observable<AnywhereDataResponse<AnywhereMetaData>>();
    }
  }

  private generateAnywhereSearchQuery(data: { [key: string]: (string | { [key: string]: string }) }): { query?: string } {
    return data && Object.keys(data).length > 0
      ? {query: `(${this.generateSearchQueryFilters(data, ' AND ')})`}
      : {};
  }

  private generateSearchQueryFilters(data: { [key: string]: (string | { [key: string]: string }) }, operatorKey: (' AND ' | ' OR ')): string {
    const queryParam: string[] = [];

    Object.keys(data).forEach(key => {
      const value = data[key];

      if (typeof value === 'string') {
        queryParam.push(`${key}:(${value})`);
      } else {
        queryParam.push(`(${this.generateSearchQueryFilters(value, ' OR ')})`);
      }
    });

    return queryParam.join(operatorKey);
  }
}
