import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'sa-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent {
  private static readonly baseClass = 'content-sidebar';

  @HostBinding('class') hostClass: string;
  @HostBinding('class.active') isHostActive: boolean;
  @Input() itemList = [];
  @Input() nameButton: string;
  @Input() isSidebarImage: boolean;
  @Input() isSidebarTitle: boolean;

  constructor() {
    this._className = 'left';
    this.nameButton = '';
    this.isHostActive = false;

    this.setHostClass();
  }

  private _className: string;

  @Input() set className(value: string) {
    this._className = value;
    this.setHostClass();
  }

  toggleSidebar() {
    this.isHostActive = !this.isHostActive;
  }

  private setHostClass(): void {
    this.hostClass = `${SidebarComponent.baseClass} ${this._className}`;
  }
}
