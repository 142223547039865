<nav class="navbar-mobile">
  <div class="logo-mobile">
    <a routerLink="/home"></a>
  </div>
  <div *ngIf="liveItem"
       class="menu-mobile">
    <a [ngClass]="{'live': liveItem.isLive}"
       [routerLink]="liveItem.link"
       class="top-menu"
       routerLinkActive="selected"><span [textContent]="liveItem.label"></span></a>
  </div>
  <div [ngClass]="{'active': isLoggedIn}" class="user-component-mobile navbar-component">
    <div (click)="menuToggle(true)" *ngIf="buttonOpen" class="navbar-toggler">
      <svg aria-hidden="true" class="icon icon-menu-toggle" height="20" viewBox="0 0 24.444 20" width="24.444" x="0px"
           xmlns="http://www.w3.org/2000/svg" y="0px">
        <g class="link-hover svg-menu-toggle" transform="translate(-336 -22)">
          <rect class="line line-1" height="2" transform="translate(336 22)" width="24.444"/>
          <rect class="line line-2" height="2" transform="translate(347.004 31)" width="13.44"/>
          <rect class="line line-3" height="2" transform="translate(336 40)" width="24.444"/>
        </g>
      </svg>
    </div>
    <div (click)="menuToggle(false)" *ngIf="buttonClose" class="close"></div>
  </div>
</nav>
