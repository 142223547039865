import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';

@Component({
  selector: 'sa-signup-btn',
  templateUrl: './signup-btn.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignupBtnComponent implements OnInit {
  
  /*
  * Button open page registration
  * param {registerLink}
  */

  @Input() className = 'btn';
  @Input() nameRegisterBtn = "Registration";
  @Input() registerLink: string;

  constructor() {
  }

  ngOnInit() {

  }

  isExternal(link): boolean {
    const pat = /^https?:\/\//i;
    return pat.test(link);
  }

}
