import {ChangeDetectionStrategy, Component, OnInit, Input} from '@angular/core';
import {ModalService} from '../../../services/modal.service';

@Component({
  selector: 'sa-login-btn',
  templateUrl: './login-btn.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginBtnComponent implements OnInit {

  /*
  * Button open form modal Login
  */
  @Input() className = 'btn';
  @Input() nameLoginBtn = "Login";

  constructor(private modalService:ModalService) {}

  ngOnInit() {
  }

  public openLoginModal(event): void {

    event.preventDefault();
    this.modalService.openLoginModal();
  }
}
