import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {FlatpickrOptions, Ng2FlatpickrComponent} from 'ng2-flatpickr';
import { FixtureFilters } from '../../../models/fixture/amg.fixtures';
import {formatDate} from '@angular/common';

@Component({
  selector: 'sa-date-picker-range',
  templateUrl: './date-picker-range.component.html',
  styleUrls: ['./date-picker-range.component.scss']
})
export class DatePickerRangeComponent implements OnInit {
  filters?: FixtureFilters;
  dateParts = [];
  dateMonth = [];
  teamId = '';

  public flatpickrOptions: FlatpickrOptions = {
    enableTime: false,
    defaultDate: null,
    mode: 'range',
    dateFormat: 'd-m-Y',
    minDate: new Date(),
    locale: this.locale,

    onChange: (selectedDates, dateStr) => {
      this.filters.from = '';
      this.filters.to = '';

      if (selectedDates.length === 2) {
        this.dateParts = dateStr.replace(/ /g, '').split('to');
        this.filterByDate();
        this.dateLabel(this.dateParts);
      } else if (selectedDates.length === 0) {
        this.filters.from = '';
        this.filters.to = '';
        //this.getFixturesList();
      }
     // this.cd.markForCheck();
    },
    wrap: true,
    allowInput: false
  };

  constructor(@Inject(LOCALE_ID) public locale: string) {
    this.filters = {
      q: '(type:fixture)',
      from: '',
      to: ''
    };
  }

  ngOnInit(): void {
  }

  filterByDate() {
    //this.translateOpened = false;
    this.filters.q = this.teamId;
    if (this.dateParts.length === 2) {
      this.filters.from = this.dateParts[0].split('-').reverse().join('-');
      this.filters.to = this.dateParts[1].split('-').reverse().join('-');
    } else if (this.dateParts.length === 1) {
      this.filters.from = this.dateParts[0].split('-').reverse().join('-');
      this.filters.to = this.dateParts[0].split('-').reverse().join('-');
    }
    //this.getFixturesList();
  }

  dateLabel(dateParts) {
    //this.translateOpenedTop = false;
    this.dateMonth = [];

    for (let i = 0; i < dateParts.length; i++) {
      this.dateMonth[i] = formatDate(dateParts[i].split('-').reverse().join('-'), 'dd LLL', this.locale);
    }
  }

}
