import { Injectable } from '@angular/core';
import { UserAuth } from './userAuth';
import { GenericPaymentResponse, LoginSubmissionForm } from '../../models/userAuth/user-auth.type';

// @dynamic
@Injectable()
export class UserAuthService extends UserAuth {
  constructor() {
    super();
    this.streamPaymentsSetSessionState();
  }
  public streamPaymentsLogin(data: LoginSubmissionForm, callback?: (response: GenericPaymentResponse) => void): void {
    this.streamPayments.doLoginPost(`emailaddress=${encodeURIComponent(data.emailaddress)}&password=${encodeURIComponent(data.password)}`, callback);
  }
}
