import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { WINDOW_PROVIDERS } from './providers/window-provider';

//Services
import { ScrollService } from './services/scroll.service';
import { AnywhereService } from './services/anywhere.service';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { FixturesService } from './services/fixtures.service';
import { FixtureService } from './services/fixture.service';
import { ScriptTagService } from './services/script-tag.service';
import { UserAuth } from './services/user-auth/userAuth';
import { UserAuthSsoService } from './services/user-auth/user-auth-sso.service';

import { VideoPlayersModule } from './modules/video-players/video-players.module';
import { CarouselModule } from './components/carousel/carousel.module';
import { CardsModule } from './components/cards/cards.module';
import { BrowseLibModule } from './components/browse/browse.module';
import { BoxsetsLibModule } from './components/boxsets/boxests.module';
import { FixturesLibModule } from './components/fixtures/fixtures.module';
import { LoaderModule } from './components/loader/loader.module';
import { SearchLibModule } from './components/search-form/search.module';
import { FilterLibModule } from './components/filter/filter.module';
import { SidebarModule } from './components/menu/sidebar/sidebar.module';
import { UserAuthLibModule } from './components/user-auth/user-auth.module';

import { CounterUpComponent } from './components/counter-up/counter-up.component';

import { GdprComponent } from './components/gdpr/gdpr.component';

import { SocialComponent } from './components/social/social.component';
import { SidebarComponent } from './components/menu/sidebar/sidebar.component';
import { AccordionModule } from './components/accordion/accordion.module';
import { FooterHorizontalComponent } from './components/footer/footer-horizontal/footer-horizontal.component';
import { FooterColumnsComponent } from './components/footer/footer-columns/footer-columns.component';


@NgModule({
  declarations: [
    CounterUpComponent,
    GdprComponent,
    SocialComponent,
    FooterHorizontalComponent,
    FooterColumnsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgSelectModule,
    AccordionModule,
    FormsModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    VideoPlayersModule,
    CarouselModule,
    CardsModule,
    BrowseLibModule,
    BoxsetsLibModule,
    FixturesLibModule,
    LoaderModule,
    SearchLibModule,
    FilterLibModule,
    SidebarModule,
    UserAuthLibModule
  ],
  exports: [
    CounterUpComponent,
    GdprComponent,
    SocialComponent,
    SidebarComponent,
    FooterHorizontalComponent,
    FooterColumnsComponent
  ]
})
export class ComponentsLibModule {
  // To also load services, use forRoot.
  static forRoot(): ModuleWithProviders<ComponentsLibModule> {
    return {
      ngModule: ComponentsLibModule,
      providers: [
        WINDOW_PROVIDERS,
        FixtureService,
        FixturesService,
        AnywhereService,
        ScriptTagService,
        UserAuthSsoService
      ]
    };
  }
}
