import {
  Component,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'sa-search-header',
  templateUrl: './search-header.component.html',
  styleUrls: ['./search-header.component.scss']
})
export class SearchHeaderComponent {
  @Output() searchTrigger: EventEmitter<string>;
  public isFocused: boolean;
  public searchOpened: boolean;
  @Input() placeholder: string;
  public form: FormGroup;
  private previousEmittedValue: string;

  constructor() {
    this.searchTrigger = new EventEmitter<string>();
    this.searchOpened = false;
    this.isFocused = false;
    this.form = new FormGroup({
      search: new FormControl()
    });
  }

  toggleSearch(inputElement: HTMLInputElement) {
    if (this.searchOpened) {
      this.closeSearch();
    } else {
      this.openSearch(inputElement);
    }
  }

  openSearch(inputElement: HTMLInputElement) {
    this.searchOpened = true;
    setTimeout(() => {
      inputElement.focus();
    }, 500);
  }

  closeSearch() {
    this.searchOpened = false;
  }

  submitSearch(event: Event, inputElement: HTMLInputElement) {
    event.preventDefault();
    const search = this.form.get('search').value;
    this.closeSearch();
    if (search !== '') {
      this.form.setValue({
        search: '',
      });
      this.searchTrigger.emit(search);
      inputElement.blur();
    }
  }

  handleSearch(event: KeyboardEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.triggerSearch(event);
  }

  /**
   * IE 11 fix as it doesn't support type='search'
   */
  handleKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.triggerSearch(event);
    }
  }

  onFocus(isFocused: boolean): void {
    this.isFocused = isFocused;
  }

  private triggerSearch(event: KeyboardEvent): void {
    const searchValue = (event.target as HTMLInputElement).value;

    if (searchValue !== this.previousEmittedValue) {
      this.searchTrigger.emit(searchValue);
    }

    this.previousEmittedValue = searchValue;
  }

}
