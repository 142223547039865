import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {VideoPlaylist} from '../../models/playlist/video.type';
import {AnywhereItemData} from '../../models/anywhere/anywhere-api.type';
import {GetMetaData} from '../../models/cards/cards.type';
import {AnywhereMetaData} from '../../../../../../src/app/core/models/anywhere-metadata.type';

@Component({
  selector: 'sa-browse',
  templateUrl: './browse.component.html',
  styleUrls: ['./browse.component.scss']
})
export class BrowseComponent implements OnInit {
  /**
  * input data you want to show or hide
  */
 @Input() isCategory:boolean;
 @Input() isRuntime:boolean;
 @Input() isEntitlement:boolean;
 @Input() className:string;
 @Input() isDateTimeImage: boolean;
 @Input() isDateTime: boolean;
 @Input() formatDate: string;
 @Input() path: string;

 @Input() componentData: VideoPlaylist;
 //Input layoutCard, switch from components
 @Input() layoutCard: string = 'cardVideo';

 @ViewChild('videoContainer') videoContainerRef;
 @Input() videoList: AnywhereItemData<AnywhereMetaData>[];

 @Input() getMetaData: GetMetaData<AnywhereMetaData>;

  constructor() {
  }

  ngOnInit(): void {

  }

}
