export class DeviceUtils {
  /**
   * Gets the user default locale from the navigator.
   * @param navigator The navigator from the window object.
   */
  public static getUserLocale(navigator: Navigator): string {
    const locale = (navigator.languages && navigator.languages.length)
      ? navigator.languages[0]
      : (navigator as any).userLanguage || navigator.language || (navigator as any).browserLanguage || 'en';

    return locale;
  }

  /**
   * Checks if the current device is an IOS device.
   * @param navigator The navigator from the window object.
   */
  public static isIOSDevice(navigator: Navigator): boolean {
    const isIosDevice = /iPad|iPhone|iPod/i.test(navigator.userAgent);
    return isIosDevice;
  }

  /**
   * Checks if the current device is an Android device.
   * @param navigator The navigator from the window object.
   */
  public static isAndroidDevice(navigator: Navigator): boolean {
    const isAndroidDevice = /Android/i.test(navigator.userAgent);
    return isAndroidDevice;
  }

  /**
   * Checks if the current browser is IE.
   * @param navigator The navigator from the window object.
   */
  public static isIE(navigator: Navigator): boolean {
    const isIE = /msie\s|trident\//i.test(navigator.userAgent);
    return isIE;
  }

  /**
   * Checks if the current device is a mobile device.
   * TODO: confirm iPadOS and touch devices.
   * @param navigator The navigator from the window object.
   */
  public static isMobileDevice(navigator: Navigator): boolean {
    return DeviceUtils.isIOSDevice(navigator) || DeviceUtils.isAndroidDevice(navigator);
  }
}
