export class PlayerUtils {
    /**
     * URL encode the parameters and then apply base64 encode.
     */
    public static encodeUrlParamsForPlayer(path: string): string {
        return btoa(encodeURIComponent(path));
    }

    /**
     * Base64 decode the parameters, URL decode and then split by Pipeline.
     */
    public static decodeUrlParamsForPlayer(path: string): string[] {
        return decodeURIComponent(atob(path))
            .split('|');
    }
}
