import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';

@Component({
  selector: 'sa-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  @Input() isTitleForm: boolean;
  @Input() isSubtitleForm: boolean;
  @Input() isNameLabel: boolean;
  @Input() isSurnameLabel: boolean;
  @Input() isDateOfBirthLabel: boolean;
  @Input() isEmailLabel: boolean;
  @Input() isPasswordLabel: boolean;

  //Text Label
  @Input() titleForm: string = 'Title Form';
  @Input() subtitleForm: string = 'Subtitle Form';
  @Input() nameLabel: string = 'Name'
  @Input() surnameLabel: string = 'Surname';
  @Input() dateOfBirthLabel: string = 'Date Of Birth';
  @Input() emailLabel: string = 'Email';
  @Input() passwordLabel: string = 'Password';

  //PlaceHolder
  @Input() namePlaceHolder: string = 'Name';
  @Input() surnamePlaceHolder: string = 'Surname';
  @Input() passwordPlaceHolder: string = 'Password';
  @Input() emailPlaceHolder: string = 'Email Address';
  @Input() dateOfBirthPlaceHolder: string = 'dd/mm/yyyy';

  @Input() nameRegisterBtn: string = 'Registration';

  @Input() errorFieldRequired: string = "Field is required";
  @Input() errorEmailRequired: string = "Email is required";
  @Input() invalidEmail: string = "Invalid Email";
  @Input() errorPwdRequired: string = "Password is required";
  @Input() errorPwdLength: string = "Password need to be min. 6";
  @Input() passwordLength: number = 6;
  @Input() errorServer:string;

  registrationForm: FormGroup;

  constructor(private formBuilder: FormBuilder) 
  { }

  ngOnInit() {
    this.registrationForm = this.formBuilder.group({
      name: new FormControl('', Validators.compose([
        Validators.required
      ])),
      surname: new FormControl('', Validators.compose([
        Validators.required
      ])),
      dateOfBirth: new FormControl('', Validators.compose([
        Validators.required
      ])),
      emailaddress: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(this.passwordLength)
      ]))
    });
  }

  protected validateAllFormFields() {
    Object.keys(this.registrationForm.controls).forEach(field => {
      const control = this.registrationForm.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields();
      }
    });
  }

  @Output() submit = new EventEmitter<string>();
  
  formSubmit(ev){
    ev.stopPropagation();
    ev.preventDefault();
    if (this.registrationForm.dirty && this.registrationForm.valid)
        this.submit.emit(this.registrationForm.value);
    else
        this.validateAllFormFields();
  }

}
