import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { DatePickerRangeComponent } from './date-picker-range/date-picker-range.component';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';

@NgModule({
  declarations: [ 
    DatePickerRangeComponent,
    ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    Ng2FlatpickrModule
  ],
  exports: [
    DatePickerRangeComponent
  ]
})
export class DatePickerLibModule {
 
}
