

<div class="footer-list">
    <div class="content-list">
        <div class="column" *ngFor="let item of footerItemList">
            <h3>{{item.title}}</h3>
            <div *ngFor="let data of item.link">
                <ng-container *ngIf="isExternal(data.link); else internal">
                    <a [attr.href]="data.link" [textContent]="data.label"></a>
                </ng-container>
                <ng-template #internal>
                    <a [routerLink]="[data.link]" [textContent]="data.label"></a>
                </ng-template>
            </div>
        </div>
    </div>
</div>
