import {Injectable} from '@angular/core';
import {AmgFixture, FixtureService, FixturesService, AmgFixtureMediaData} from 'components-lib';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FixtureLiveService {
  private isLiveFixtureMap: { [entryId: string]: boolean; };
  private liveCountSubject: BehaviorSubject<number>;

  constructor(private fixturesService: FixturesService,
              private fixtureService: FixtureService) {
    this.isLiveFixtureMap = {};
    this.liveCountSubject = new BehaviorSubject<number>(0);

    this.fixturesService
      .triggerApiCall(environment.fixtures.url, '25', null, null)
      .subscribe(fixtureHeroState => {
        if (fixtureHeroState?.data?.success.data.length > 0) {
          this.processFixtures(fixtureHeroState.data.success.data);
        }
      });
  }

  public getLiveFixtures(): { count: number; liveCount: Observable<number>; } {
    return {
      count: this.countLiveFixtures(),
      liveCount: this.liveCountSubject.asObservable()
    };
  }

  private countLiveFixtures(): number {
    return Object.keys(this.isLiveFixtureMap)
      .map(entryId => this.isLiveFixtureMap[entryId]) // Map to isLive
      .filter(value => value) // Filter for true values
      .length;
  }

  private processFixtures(fixtures: AmgFixture[]): void {
    fixtures.forEach(item => {
      if (item.mediaData.length > 0) {
        const entryData = item.mediaData[0];

        this.fixtureService.isLiveApiCall(entryData).subscribe(isLive => {
          this.processFixture(entryData, isLive);
        });
      }
    });
  }

  private processFixture(entryData: AmgFixtureMediaData, isLive: boolean): void {
    this.isLiveFixtureMap = {
      ...this.isLiveFixtureMap,
      [entryData.entryId]: isLive
    };
    this.liveCountSubject.next(this.countLiveFixtures());
  }
}
