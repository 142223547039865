import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CardHorizontalComponent } from './card-horizontal/card-horizontal.component';
import { CardVideoComponent } from '../cards/card-video/card-video.component';
import { CardPlayerComponent } from '../cards/card-player/card-player.component';
import { CardFixtureComponent } from '../cards/card-fixture/card-fixture.component';
import { CardFixtureMinComponent } from '../cards/card-fixture-min/card-fixture-min.component';
import { PipeModule } from '../../utils/pipe/pipe.module';
import { CalendarEventsComponent } from '../calendar-events/calendar-events.component';
import { CardGameComponent } from './card-game/card-game.component';
import {SharedModule} from '../../../../../../src/app/shared/shared.module';

@NgModule({
  declarations: [
    CardHorizontalComponent,
    CardPlayerComponent,
    CardVideoComponent,
    CardFixtureComponent,
    CardFixtureMinComponent,
    CalendarEventsComponent,
    CardGameComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        LazyLoadImageModule,
        PipeModule,
        SharedModule
    ],
  exports: [
    CardHorizontalComponent,
    CardVideoComponent,
    CardPlayerComponent,
    CardFixtureComponent,
    CardFixtureMinComponent,
    CalendarEventsComponent,
    CardGameComponent
  ]
})
export class CardsModule {

}
