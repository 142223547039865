<div class="card-horizontal-container">
    <div class="image-container">
        <a [routerLink]="routerLink">
            <img [lazyLoad]="linkImg" [defaultImage]="defaultImage" />
        </a>
        <div *ngIf="isEntitlement" class="entitlement" [attr.data-entitlement]="entitlementKey" [textContent]="entitlement"></div>
    </div>
    <div class="card-info">
        <div *ngIf="isTitle" class="title" [textContent]="title"></div>
        <div *ngIf="isDescription" class="description" [textContent]="description | truncate : truncateText"></div>
    </div>

    <div *ngIf="isRuntime" class="runtime ">
        <span>{{runtime}}</span>
    </div>

    <div class="card-button">
        <a class="button" [routerLink]="routerLink">
            {{buttonName}}
        </a>
    </div>
</div>
