export enum UserState {
  subscribed = 'subscribed',
  loggedIn = 'loggedIn',
  notLoggedIn = 'notLoggedIn'
}

export interface LoginSubmissionForm {
  emailaddress: string;
  password: string;
}

export interface Init {
  jwtToken?: string;
  language?: string | 'en';
}

export interface KSessionRequest {
  entryId: string;
  apisessionid?: string;
}

export interface GenericPaymentResponse {
  ErrorMessage?: string;
  AuthenticationToken?: string;
  CurrentCustomerSession?: CurrentCustomerSession;
  CurrentCustomerSessionStatus?: number;
  KSession?: string;
  Status?: number;
  LocationFromIp?: { string };
  ModelErrors?: { string };
  PageUrl?: string;
  UpdatedAt?: string;
  UtcNow?: string;
  JwtTokenCacheKey?: string;
}

export interface CurrentCustomerSession {
  Id: string;
  CustomerId: string;
  CustomerDeleted: boolean;
  CustomerFirstName: string;
  CustomerLastName: string;
  CustomerEmailAddress: string;
  CustomerSubscriptionCount: number;
  CustomerNonExpiringSubscriptionCount: number;
  CustomerEntitlements: any;
  CustomerFullAccessUntil: any;
  CustomerBillingProfileProvider: any;
  CustomerBillingProfileReference: any;
  CustomerBillingProfileExpiresAt: any;
  CustomerBillingProfileCreatedAt: any;
  CustomerBillingProfileLastFailedAt: any;
}


export interface UserStateObservable {
  state: UserState;
  details?: GenericPaymentResponse;
}

export interface StreamPayments {
  init?(data: Init): any;

  doLogin?(data: LoginSubmissionForm, callback?: object): GenericPaymentResponse;

  doLoginPost?(data: string, callback?: object): any;

  doLogout?(data: object, callback?: object): any;

  getKSession?(data: KSessionRequest, callback?: object): GenericPaymentResponse;

  getSessionState?(data: null, callback?: object): GenericPaymentResponse;

  getSubscriptionPackageList?(data: null, callback?: object): SubscriptionPackageListResponse;

  isEmailAddressRegistered?(data: object, callback?: object): any;

  isEmailAddressValid?(data: object, callback?: object): any;

  isVoucherCodeValid?(data: object, callback?: object): any;
}

export interface SubscriptionPackageListResponse extends SubscriptionPlan {
  SubscriptionPlanOptions?: SubscriptionPlan[];
}

export interface SubscriptionPlan {
  Id: string;
  Title: string;
  Body: string;
  Style: string;
  Amount: number;
  Currency: string;
  AmountFormatted: string;
  HasFreeTrial: boolean;
  TrialDuration: number;
  PurchaseUrl: any;
  Interval: string;
  Duration: number;
}
