import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {WordpressService} from 'wordpress-lib';
import {environment} from '../../../environments/environment';

export interface LabelDictionary {
  [labelKey: string]: BehaviorSubject<string>;
}

export interface CommonLabel {
  [key: string]: string
}

@Injectable({
  providedIn: 'root'
})
export class LabelService {
  private labelDictionary: LabelDictionary;

  constructor(private wordpressService: WordpressService) {
    this.labelDictionary = {};

    const commonDataUrl = `${environment.wordpress.api}${environment.wordpress.commonData}`;

    this.wordpressService.getWordpressData<any>(commonDataUrl)
      .subscribe(commonData => {
        if (commonData && commonData.labels) {
          this.addLabels(commonData.labels);
        }
      });
  }

  public getLabel(key: string): Observable<string> {

    if (!this.labelDictionary[key]) {
      this.labelDictionary[key] = new BehaviorSubject<string>('');
    }

    return this.labelDictionary[key].asObservable();
  }

  private addLabels(labels: CommonLabel): void {
    if (labels && Object.keys(labels).length) {
      Object.keys(labels).forEach(key => {
        const label = labels[key];

        if (this.labelDictionary[key]) {
          this.labelDictionary[key].next(label);
        } else {
          this.labelDictionary[key] = new BehaviorSubject<string>(label);
        }
      });
    }
  }
}
