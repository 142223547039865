import {HttpClient} from '@angular/common/http';

import {Observable, ReplaySubject} from 'rxjs';
import {Injectable} from '@angular/core';
import {FixtureFilters, FixturePagingData, FixturesState} from '../models/fixture/amg.fixtures';
import {FixtureUtils} from '../utils/fixture-utils';
import {AmgFixtureFeed} from '../models/fixture/amg.fixture';

// @dynamic
@Injectable()
export class FixturesService {

  private cache: { [url: string]: ReplaySubject<FixturesState>; };

  constructor(private httpClient: HttpClient) {

    this.cache = {};
  }

  private static setFixturesStateOnError(fixturesState: FixturesState): FixturesState {
    return {
      isLoading: false,
      data: {
        ...(fixturesState.data && fixturesState.data.success ? {success: fixturesState.data.success} : {}),
        error: true
      }
    };
  }

  private static setFixturesStateNoMoreData(fixturesState: FixturesState): FixturesState {
    return {
      isLoading: false,
      data: {
        success: {
          data: (fixturesState.data && fixturesState.data.success)
            ? fixturesState.data.success.data
            : [],
          paginationData: (fixturesState.data && fixturesState.data.success)
            ? fixturesState.data.success.paginationData
            : null
        }
      }
    };
  }

  private static setFixturesStateAndAppendData(fixturesState: FixturesState, data: AmgFixtureFeed, appendData?: boolean): FixturesState {
    const {fixtures, ...paginationData} = data;

    return {
      isLoading: false,
      data: {
        success: {
          data: (appendData && fixturesState.data && fixturesState.data.success)
            ? [
              ...fixturesState.data.success.data,
              ...fixtures
            ]
            : fixtures,
          paginationData
        }
      }
    };
  }

  private static getPagingData(fixturesState: FixturesState): FixturePagingData {
    return (fixturesState && fixturesState.data && fixturesState.data.success)
      ? fixturesState.data.success.paginationData
      : null;
  }

  public triggerApiCall(apiUrl: string, pageSize: string, fixturesState?: FixturesState, filters?: FixtureFilters, appendData?: boolean): Observable<FixturesState> {

    let noMoreData = false;
    let fixtureUrl = '';

    filters = filters || {};
    const pagingData = FixturesService.getPagingData(fixturesState);

    if (pagingData) {
      fixtureUrl = FixtureUtils.generateFixtureUrl(apiUrl, pagingData, filters);
      if (!FixtureUtils.isMoreDataAvailable(pagingData)) {

        fixturesState = FixturesService.setFixturesStateNoMoreData(fixturesState);
        noMoreData = true;

      }
    } else {
      fixtureUrl = FixtureUtils.generateInitFixtureUrl(apiUrl, filters, pageSize);
    }

    if (!this.cache[fixtureUrl]) {

      if (noMoreData) {

        this.cache[fixtureUrl].next(fixturesState);
        // this.cache[fixtureUrl].complete();

      } else {

        this.cache[fixtureUrl] = new ReplaySubject<FixturesState>(1);

        this.httpClient.get<AmgFixtureFeed>(fixtureUrl).subscribe(data => {
          if (data) {

            fixturesState = FixturesService.setFixturesStateAndAppendData(fixturesState, (data as AmgFixtureFeed), appendData);

            this.cache[fixtureUrl].next(fixturesState);
            //  this.cache[fixtureUrl].complete();
          }
        }, error => {
          fixturesState = FixturesService.setFixturesStateOnError(fixturesState);
          this.cache[fixtureUrl].next(fixturesState);
          //  this.cache[fixtureUrl].complete();
        });
      }
    }

    return this.cache[fixtureUrl].asObservable();
  }

  public triggerLoading(fixturesState: FixturesState): FixturesState {
    return {
      ...fixturesState,
      isLoading: true
    };
  }

  public isMoreDataAvailable(fixturesState: FixturesState): boolean {
    const pagingData = FixturesService.getPagingData(fixturesState);
    return (!!pagingData && FixtureUtils.isMoreDataAvailable(pagingData));
  }

}
