import { Component, OnInit, ViewEncapsulation, Input, ChangeDetectorRef, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';


@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderMobileComponent implements OnInit {
  @Input() liveItem: {label: string; link: string; isLive: boolean};
  @Output() toggleleMenu = new EventEmitter<boolean>();
  @Input() buttonOpen:boolean;
  @Input() buttonClose:boolean;
  @Input() isLoggedIn: boolean;
  @Input() isMyAccountPanelOpen: boolean;
  @Input() firstName: string;
  @Output() searchModal = new EventEmitter<any>();
  @Output() loginPanelClick = new EventEmitter<boolean>();


  constructor(private cd: ChangeDetectorRef ) { }

  ngOnInit(): void {
  }

  setIsMyAccountPanelOpen(isMyAccountPanelOpen: boolean): void {
    this.loginPanelClick.emit(isMyAccountPanelOpen);
  }

  openSearchModal(ev){
    // this.searchModal.next(ev);
    // this.cd.markForCheck();
  }

  // mobile version
  menuToggle(val){
    this.toggleleMenu.emit(val);
    this.cd.markForCheck();
  }

}
