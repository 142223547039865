import {ChangeDetectorRef} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {formatDate} from '@angular/common';
import {FixtureService} from '../../services/fixture.service';
import {Subject, Subscription} from 'rxjs';
import {
  AmgEvent,
  AmgFixture,
  AmgFixtureCompetition,
  AmgFixtureMediaData,
  AmgFixtureTeam,
  AmgFixtureThumbnailFlavors,
  AmgFixtureTeamType, AmgFixtureEvent
} from '../../models/fixture/amg.fixture';

export class FixtureBaseComponent {
  private mediaDataEntryIsLiveSubject = new Subject<AmgFixtureMediaData>();
  public mediaDataEntryIsLive$ = this.mediaDataEntryIsLiveSubject.asObservable();
  public fixtureData: AmgFixtureEvent;
  public isLiveSubscription: Subscription;
  public fixtureMediaData: { video: AmgFixtureMediaData[]; audio: AmgFixtureMediaData[]; };

  private static generateMediaObject(mediaData: AmgFixtureMediaData): AmgFixtureMediaData {
    return {
      ...mediaData,
      isEntryCurrentlyLive: false
    };
  }

  constructor(protected http: HttpClient,
              protected fixtureService: FixtureService,
              protected changeDetectorRef: ChangeDetectorRef) {

    const base: any = {
      id: null,
      name: '',
    };

    const date = '1970-01-01T00:00:00.000Z';

    this.fixtureData = {
      homeTeam: base as AmgFixtureTeam,
      awayTeam: base as AmgFixtureTeam,
      competition: base as AmgFixtureCompetition,
      startDate: date,
      endDate: date,
    } as any as AmgFixture;
  }

  setFixtureData(fixtureData: (AmgEvent | AmgFixture)) {
    this.fixtureData = fixtureData;
  }

  getFixtureDate(): string {
    return this.fixtureData.startDate;
  }

  getName(): string {
    return this.fixtureData.name;
  }

  getDescription(): string {
    return this.fixtureData.description;
  }

  getEventType(): string {
    return (this.fixtureData as AmgEvent).eventType
      ? (this.fixtureData as AmgEvent).eventType
      : '';
  }

  getCompetition(): AmgFixtureCompetition {
    return (this.fixtureData as AmgFixture).competition
      ? (this.fixtureData as AmgFixture).competition
      : null;
  }

  getTeamType(): AmgFixtureTeamType {
    return (this.fixtureData as AmgFixture).teamType
      ? (this.fixtureData as AmgFixture).teamType
      : null;
  }

  getDuration(): number {
    return (this.fixtureData as AmgFixture).duration
      ? Number.parseInt((this.fixtureData as AmgFixture).duration, 10) * 1000
      : -1;
  }

  getStadiumName(): string {
    return (this.fixtureData as AmgFixture).stadium
      ? (this.fixtureData as AmgFixture).stadium.name
      : '';
  }

  getAwayTeamData(): AmgFixtureTeam {
    return (this.fixtureData as AmgFixture).awayTeam
      ? (this.fixtureData as AmgFixture).awayTeam
      : {id: null, name: null, logo: null, logoFlavors: null};
  }

  getHomeTeamData(): AmgFixtureTeam {

    return (this.fixtureData as AmgFixture).homeTeam
      ? (this.fixtureData as AmgFixture).homeTeam
      : {id: null, name: null, logo: null, logoFlavors: null};
  }

  isThumbnailAvailable(): boolean {
    return this.fixtureData.thumbnail && this.fixtureData.thumbnail.length > 0;
  }

  getMediaData(): { video: AmgFixtureMediaData[]; audio: AmgFixtureMediaData[]; } {
    return this.fixtureMediaData;
  }


  getFormattedDate(dateFormat: string, locale?: string): string {
    return formatDate(this.getFixtureDate(), dateFormat, locale || 'en');
  }

  public init(): void {
    this.fixtureMediaData = this.flattenMediaData(this.fixtureData.mediaData);
    this.triggerFixturePolling();
  }


  public isMediaLive(mediaType: string): boolean {
    return this.fixtureMediaData[mediaType].filter(value => !!value.isEntryCurrentlyLive).length > 0;
  }

  public getEntryId(mediaType: string): string {

    return this.fixtureMediaData[mediaType] && this.fixtureMediaData[mediaType][0] ? this.fixtureMediaData[mediaType][0].entryId : null;
  }

  public isAnyFixtureLive(): boolean {
    /**
     * Check >video< entries first,
     *  and then finish off with >audio<.
     */
    if (this.isMediaLive('video')) {
      return true;
    } else {
      return this.isMediaLive('audio');
    }
  }

  public isTypeEvent(): boolean {
    return !!(this.fixtureData as AmgEvent).eventType || (!this.fixtureData.awayTeam && !this.fixtureData.homeTeam);
  }

  private triggerFixturePolling(): void {
    this.triggerFixtureIsLive('audio');
    this.triggerFixtureIsLive('video');
  }

  private triggerFixtureIsLive(mediaType: string): void {
    this.fixtureMediaData[mediaType].forEach((entry, index) => {

      this.fixtureMediaData[mediaType][index] = entry;

      const isLiveObservable = this.fixtureService.isLiveApiCall(entry);

      if (isLiveObservable != null) {
        this.isLiveSubscription = isLiveObservable.subscribe((isLive) => {

          this.fixtureMediaData[mediaType][index].isEntryCurrentlyLive = isLive;
          this.mediaDataEntryIsLiveSubject.next(this.fixtureMediaData[mediaType][index]);
          this.changeDetectorRef.markForCheck();
        });
      }
    });
  }

  private flattenMediaData(mediaData: AmgFixtureMediaData[]): { video: AmgFixtureMediaData[]; audio: AmgFixtureMediaData[]; } {

    const flatMediaData: { video: AmgFixtureMediaData[]; audio: AmgFixtureMediaData[]; } = {
      video: [],
      audio: []
    };

    if (mediaData) {

      mediaData.forEach(value => {
        flatMediaData[value.mediaType].push(FixtureBaseComponent.generateMediaObject(value));
      });
    }
    return flatMediaData;
  }

  public destroy(): void {

    if (this.isLiveSubscription) {
      this.isLiveSubscription.unsubscribe();
    }
  }
}
