<div class="container-fixture-card" [class.event]="isTypeEvent()" [class.is-live]="isAnyFixtureLive()">

  <ng-container *ngIf="!isTypeEvent(); else event">
    <div class="left">
      <div *ngIf="labelLiveTag && isAnyFixtureLive()" class="live-tag">
        <span [innerText]="labelLiveTag"></span>
      </div>
      <div>
        <!-- Competition  -->
        <div *ngIf="getCompetition()" class="competition" [textContent]="getCompetition()?.name"></div>
        <div *ngIf="getDescription()" [innerHTML]="getDescription()"></div>
        <div *ngIf="getTeamType()" class="team-type" [textContent]="getTeamType()?.name"></div>
      </div>


      <!--Calendar Events-->
      <div class="calendar-events-container" *ngIf="!isAnyFixtureLive() && isCalendarEvents">
        <sa-calendar-events
          [indexCalendar]="indexCalendar"
          [calendarButtonName]="calendarButtonName"
          [isCalendarEvents]="isCalendarEvents"
          [dataCalendar]="dataCalendar"
          (onEventCalendar)="onCalendarEvent()"></sa-calendar-events>
      </div>
    </div>
    <div class="teams center">
      <div class="team team-home ">
        <div class="badge lazyload-image" *ngIf="getHomeTeamData().logo"
             [lazyLoad]="getHomeTeamData().logoFlavors['100']">
        </div>
        <label class="team-name" [textContent]="getHomeTeamData().name"></label>
      </div>

      <div *ngIf="isDate" class="date-content">
        <div class="time" [textContent]="getTime()"></div>
        <div class="date">{{date}}</div>
      </div>


      <div class="team team-away ">
        <div class="badge lazyload-image" *ngIf="getAwayTeamData().logo"
             [lazyLoad]="getAwayTeamData().logoFlavors['100']">
        </div>
        <label class="team-name" [textContent]="getAwayTeamData().name"></label>
      </div>


      <!--Calendar Events-->
      <div class="calendar-events-container">
        <sa-calendar-events *ngIf="!isAnyFixtureLive() && isCalendarEvents"
                            [indexCalendar]="indexCalendar"
                            [calendarButtonName]="calendarButtonName"
                            [isCalendarEvents]="isCalendarEvents"
                            [dataCalendar]="dataCalendar"
                            (onEventCalendar)="onCalendarEvent()"></sa-calendar-events>
      </div>
    </div>
    <div class="right">

      <div *ngIf="isAnyFixtureLive()" class="cta-container">
        <a [routerLink]="link" class="cta" [textContent]="labelLiveButton"></a>
      </div>
      <div class="location" [textContent]="getStadiumName()"></div>
    </div>
  </ng-container>

  <ng-template #event>
    <div class="left">
      <div *ngIf="labelLiveTag && isAnyFixtureLive()" class="live-tag">
        <span [innerText]="labelLiveTag"></span>
      </div>
      <div *ngIf="isThumbnailAvailable()" [lazyLoad]="fixtureData.thumbnailFlavors['640']"
           class="thumbnail lazyload-image">
      </div>
      <!--Calendar Events-->
      <div class="calendar-events-container" *ngIf="!isAnyFixtureLive() && isCalendarEvents">
        <sa-calendar-events
          [indexCalendar]="indexCalendar"
          [calendarButtonName]="calendarButtonName"
          [isCalendarEvents]="isCalendarEvents"
          [dataCalendar]="dataCalendar"
          (onEventCalendar)="onCalendarEvent()"></sa-calendar-events>
      </div>
    </div>
    <div class="center">
      <div class="text">
        <h2 class="title" [innerText]="getName()"></h2>

        <h3 class="description" [innerText]="getDescription()"></h3>
      </div>
      <!--Calendar Events-->
      <div class="calendar-events-container" *ngIf="!isAnyFixtureLive() && isCalendarEvents">
        <sa-calendar-events
          [indexCalendar]="indexCalendar"
          [calendarButtonName]="calendarButtonName"
          [isCalendarEvents]="isCalendarEvents"
          [dataCalendar]="dataCalendar"
          (onEventCalendar)="onCalendarEvent()"></sa-calendar-events>
      </div>
    </div>
    <div class="right">
      <div *ngIf="!isAnyFixtureLive() && isDate" class="date-content">
        <div class="time" [textContent]="getTime()"></div>
        <div class="date">{{date}}</div>
      </div>

      <div *ngIf="isAnyFixtureLive()" class="cta-container">
        <a [routerLink]="link" class="cta" [textContent]="labelLiveButton"></a>
      </div>
      <div class="location" [textContent]="getStadiumName()"></div>
    </div>
  </ng-template>
</div>

