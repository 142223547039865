import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BoxsetsComponent } from './boxsets.component';

@NgModule({
  declarations: [ 
    BoxsetsComponent
    ],
  imports: [
    RouterModule,
    CommonModule,
    LazyLoadImageModule
  ],
  exports: [
    BoxsetsComponent
  ]
})
export class BoxsetsLibModule {
 
}
