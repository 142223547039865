import { UrlUtils } from './url-utils';

export class FixtureUtils {
    public static isMoreDataAvailable(pagingData: { limit: number; offset: number; total: number; }): boolean {
        return !(pagingData.offset + pagingData.limit >= pagingData.total);
    }

    public static generateFixtureUrl(fixtureUrl: string,
                                     pagingData: { limit: number; offset: number; total: number; },
                                     urlParameters?: { [key: string]: (string | { [key: number]: string }) }): string {

        const urlParamsObject = {
            ...FixtureUtils.generateFixturesUrlParameters(urlParameters, fixtureUrl),

            limit: (pagingData.limit).toString(),
            offset: (pagingData.offset + pagingData.limit).toString()
        };

        const urlWithoutParams = UrlUtils.removeQueryParameters(fixtureUrl);
        const urlParams = UrlUtils.generateUrlParameters(urlParamsObject);

        return `${urlWithoutParams}?${urlParams}`;
    }

    public static generateInitFixtureUrl(
        fixtureUrl: string,
        urlParameters?: { [key: string]: (string | { [key: number]: string }) },
        defaultPageSize?: string) {

        const urlParamsObject = {
            ...FixtureUtils.generateFixturesUrlParameters(urlParameters, fixtureUrl),

            offset: '0',
            ...(typeof defaultPageSize === 'string' ? {limit: defaultPageSize} : {})
        };

        const urlWithoutParams = UrlUtils.removeQueryParameters(fixtureUrl);
        const urlParams = UrlUtils.generateUrlParameters(urlParamsObject);

        return `${urlWithoutParams}?${urlParams}`;
    }

    private static generateFixturesUrlParameters(data: { [key: string]: (string | { [key: number]: string }) },
                                                 fixtureUrl: string): { [key: string]: (string) } {

        const {q: querySearchParameter, ...existingUrlParameters} = UrlUtils.extractUrlParamsFromExistingUrl(fixtureUrl);

        const fixturesParameters = FixtureUtils._generateFixturesUrlParameters(data);

        if (querySearchParameter) {
            if (fixturesParameters.q) {
                fixturesParameters.q = fixturesParameters.q
                    .concat(' AND ')
                    .concat(querySearchParameter);
            } else {
                fixturesParameters.q = querySearchParameter;
            }
        }

        return {
            ...existingUrlParameters,
            ...fixturesParameters
        };
    }

    private static _generateFixturesUrlParameters(
        data: { [key: string]: (string | { [key: number]: string }) }): { [key: string]: (string) } {

        const fixturesParameters: { [key: string]: string } = {};

        Object.keys(data).forEach(key => {
            const value = data[key];

            if (typeof value === 'string') {
                fixturesParameters[key] = value;
            } else {
                const queryParameterData: string[] = [];

                Object.keys(value).forEach(index => {
                    queryParameterData.push(value[index]);
                });

                fixturesParameters[key] = queryParameterData.join(' AND ');
            }
        });

        return fixturesParameters;
    }
}
