import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {configs} from '../../../core/configs';

@Component({
  selector: 'app-sso-login',
  templateUrl: './sso-login.component.html',
  styleUrls: ['./sso-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SsoLoginComponent implements OnInit, OnDestroy {
  @Input() className = 'btn';
  @Input() nameLoginBtn = '';

  public link: string;

  private subscription: Subscription;


  constructor(private router: Router) {
    this.link = environment.sso.url + configs.sso.paths.login;
  }

  ngOnInit(): void {
    this.subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe( (event: NavigationEnd) => {
        this.setSsoLink();
      });

    this.setSsoLink();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setSsoLink(): void {
    this.link = `${environment.sso.url + configs.sso.paths.login}?redirectUrl=${window.location.href}`;
  }
}
