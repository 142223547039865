
<div class="card-game-container">
    <!--<a [routerLink]="link" [className]='className'></a>-->
    <div (click)="routing(link)"></div>
    <div class="image-container">
      <div class="image lazyload-image" [lazyLoad]="linkImg">
        <img class="image-bw" [src]="linkLogoImg_bw"/>
        <img class="image-color" [src]="linkLogoImg"/>
      </div>
    </div>
</div>

<!--
  If you decide to use with link need to add this in your style a{}
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 999;
    top:0;
-->