<div class="desktop-search-container">
  <div [class.focused]="isFocused"
       [class.opened]="searchOpened"
       class="search-container mobile-overlay desk-view">
    <div (click)="closeSearch()" class="close-btn"></div>
    <form (ngSubmit)="submitSearch($event, headerSearchBar)" [formGroup]="form" autocomplete="off">
      <div class="search-content">
        <div class="field-container">
          <input #headerSearchBar
                 (focusin)="onFocus(true)"
                 (focusout)="onFocus(false)"
                 (keyup)="handleKeyUp($event)"
                 (search)="handleSearch($event)"
                 [attr.placeholder]="placeholder"
                 formControlName="search"
                 id="header-search-bar"
                 type="search">
          <input type="submit"/>
        </div>
      </div>
    </form>
  </div>
  <div (click)="toggleSearch(headerSearchBar)" [class.opened]="searchOpened" class="search-icon desk-view"></div>
</div>
