
<form [formGroup]="loginForm" (submit)="formSubmit($event)">
    <h3 *ngIf="isTitleForm" [textContent]="titleForm"></h3>
    <fieldset>
        <label *ngIf="isUsernameLabel" [textContent]="usernameLabel"></label>
        <input formControlName="emailaddress" type="email" [placeholder]="emailPlaceHolder"/>
        <div class="error-txt"
            *ngIf="loginForm.controls['emailaddress'].touched && !loginForm.controls['emailaddress'].valid">
        <span *ngIf="loginForm.controls['emailaddress'].hasError('required')"
                [textContent]="errorEmailRequired"></span>
        <span *ngIf="loginForm.controls['emailaddress'].hasError('pattern')"
                [textContent]="invalidEmail"></span>
        </div>
    </fieldset>
    <fieldset>
        <label *ngIf="isPasswordLabel" [textContent]="passwordLabel"></label>
        <input formControlName="password" type="password" [placeholder]="passwordPlaceHolder"/>
        <div class="error-txt"
            *ngIf="loginForm.controls['password'].touched && !loginForm.controls['password'].valid">
        <span *ngIf="loginForm.controls['password'].hasError('required')"
                [textContent]="errorPwdRequired"></span>
        <span *ngIf="loginForm.controls['password'].hasError('minlength')"
                [textContent]="errorPwdLength"></span>
        </div>
    </fieldset>
    <div class="txt-forgot-pwd">
        <a target="_blank" [href]="forgotPwdUrl" [textContent]="forgotPwd"></a>
    </div>
    <div class="box-login-btn">
        <p class="error-msg">{{errorServer}}</p>
        <!--<p class="error-msg" *ngIf="formSubmissionMessageResponse.error">
        * {{formSubmissionMessageResponse.error}}</p>
        <p class="error-msg" *ngIf="formSubmissionMessageResponse.success">
        * {{formSubmissionMessageResponse.success}}</p>-->
        <button type="submit" class="btn" [textContent]="nameLoginBtn"></button>
    </div>
</form>
      
      
      