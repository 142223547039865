import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchComponent } from './search/search.component';
import { SearchHeaderComponent } from './search-header/search-header.component';
import { SearchModalComponent } from './search-modal/search-modal.component';

@NgModule({
  declarations: [ 
    SearchComponent, 
    SearchHeaderComponent, 
    SearchModalComponent
    ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule 
  ],
  exports: [
    SearchComponent, 
    SearchHeaderComponent,
    SearchModalComponent
  ]
})
export class SearchLibModule {
 
}
