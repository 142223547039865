import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  isDevMode,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { SubSink } from 'subsink';
import * as uuid from 'uuid';
import {
  KalturaEmbedElement,
  KalturaPlayerElement,
  KalturaMediaSource,
  KalturaWindow,
  PlayerChangeMedia,
  PlayerChromecastFlashVar,
  PlayerState
} from '../../../models/video/kaltura.type';
import { VideoDisplayPropertiesInternal } from '../../../models/video/video-display-properties.type';
import { VideoPlayerConfig, VideoPlayerConfigInternal } from '../../../models/video/video-player-config.type';
import { VideoPlayerProperties } from '../../../models/video/video-player-properties.type';
import { WINDOW } from '../../../providers/window-provider';
import { ScriptTagService } from '../../../services/script-tag.service';
import { ConfigurationUtils } from '../../../utils/ConfigurationUtils';
import { KalturaPlayerEvents } from '../../../models/video/kaltura.enum';

@Component({
  selector: 'sa-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPlayerComponent implements OnInit, OnDestroy, AfterViewChecked {
  private static readonly defaultKalturaEvents = new Set<KalturaPlayerEvents>([
    KalturaPlayerEvents.onChangeMedia // Required to update KS on change media
  ]);
  private static readonly cookieVolumeStorage = 'volumeControl_volumeValue';
  private static readonly volumeDefaultValue = 0.01;

  public readonly defaultPlayerId: string;
  @HostBinding('class') classAttribute;

  @ViewChild('playerEmbedElement', { static: false }) playerEmbedElement: ElementRef<HTMLDivElement>;

  private _videoPlayerConfig: VideoPlayerConfig;
  private _kalturaEvents: Set<KalturaPlayerEvents>;

  /**
   * Possibility to listen to other events outside of this component.
   */
  @Output() triggerOnPlayerEventChange: EventEmitter<PlayerState>;

  @Input() set kalturaEvents(value: Set<KalturaPlayerEvents>) {
    this._kalturaEvents = value;
    VideoPlayerComponent.defaultKalturaEvents.forEach(event => this._kalturaEvents.add(event));
  }

  get kalturaEvents(): Set<KalturaPlayerEvents> {
    return this._kalturaEvents;
  }

  @Input() mediaPlayerPlayEmitter: EventEmitter<boolean>;

  @Input() triggerPlayerMute: EventEmitter<boolean>;
  @Input() triggerPlayerSeek: EventEmitter<number>;
  @Input() triggerPlayerSendBackToLive: EventEmitter<boolean>;
  @Input() triggerPlayerFullscreen: EventEmitter<boolean>;
  @Input() triggerPlayerVolumeChange: EventEmitter<number>;
  @Input() triggerPlayerChromeCast: EventEmitter<boolean>;
  @Input() triggerChangeBitRateSource: EventEmitter<KalturaMediaSource>;

  @Input() videoPlayerProperties: VideoPlayerProperties;

  @Input() set videoPlayerConfig(playerConfig: VideoPlayerConfig) {
    if (this.videoPlayerConfig && playerConfig) {
      if (this.isOnTheSamePlayer(playerConfig)) {
        // Handle using media change.
        this.triggerMediaChange({
          ks: playerConfig?.flashvars?.ks || '',
          entryId: playerConfig.entryId
        });
      } else {
        // Different kaltura player, clear scripts and trigger init
        this.destroyScriptAndTriggerInit(playerConfig);
      }
    } else if (playerConfig) {
      this.triggerPlayerInit(playerConfig);
    }
  }

  get videoPlayerConfig(): VideoPlayerConfig {
    return this._videoPlayerConfig;
  }

  public scriptLoadProperties: VideoDisplayPropertiesInternal;
  public isPlayerLoading: boolean;

  private internalConfig: VideoPlayerConfigInternal; // Config which extends Config given by user and includes internal properties.
  private embedContainerRef: KalturaEmbedElement;
  private kalturaPlayerElementRef: KalturaPlayerElement;
  private previousVolume: number;

  /**
   * Trigger to initialize the media player after view re-render
   */
  private triggerLoadPlayer: boolean;

  private playerMediaStateSubject: BehaviorSubject<PlayerState>;
  private subSink: SubSink;

  constructor(private scriptTagService: ScriptTagService,
              private changeDetectorRef: ChangeDetectorRef,
              @Inject(WINDOW) private window: KalturaWindow,
              private cookieService: CookieService,
              private ngZone: NgZone) {
    this.classAttribute = 'video-player';
    this.defaultPlayerId = `p-${uuid.v4()}`;
    this.subSink = new SubSink();
    this._kalturaEvents = VideoPlayerComponent.defaultKalturaEvents;

    this.scriptLoadProperties = {
      isLoading: true
    };

    this.isPlayerLoading = true;
    this.triggerLoadPlayer = false;
    this.previousVolume = VideoPlayerComponent.volumeDefaultValue;

    this.playerMediaStateSubject = new BehaviorSubject<PlayerState>(null);

    this.triggerOnPlayerEventChange = new EventEmitter<PlayerState>();
  }

  ngOnInit() {
    this.videoPlayerProperties = ConfigurationUtils.generateMissingVideoPlayerProperties(this.videoPlayerProperties);

    this.subSink.add(this.playerMediaStateSubject.asObservable()
      .subscribe(value => {
        if (value) {
          this.ngZone.run(() => {
            this.triggerOnPlayerEventChange.emit(value);
          });
        }
      })
    );

    this.listenToPlayerEmitters();
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
    this.destroyKWidget();
  }

  ngAfterViewChecked(): void {
    if (this.triggerLoadPlayer) {
      this.initializePlayer();
      this.triggerLoadPlayer = false;
    }
  }

  private triggerPlayerInit(playerConfig: VideoPlayerConfig): void {
    this._videoPlayerConfig = playerConfig;
    this.internalConfig = ConfigurationUtils.generateMissingVideoPlayerConfiguration(playerConfig, this.defaultPlayerId);
    this.initializeVideoPlayer();
  }

  private initializeVideoPlayer(): void {
    this.subSink.add(this.scriptTagService.loadScript(this.internalConfig.kalturaPlayerJsUrl).subscribe(scriptTagState => {
        if (scriptTagState.isLoaded) {
          this.loadPlayerInViewAndInitPlayer();
        } else if (scriptTagState.isError) {
          this.handleScriptLoadError();
        }

        this.changeDetectorRef.markForCheck();
      }, error => {
        this.handleScriptLoadError();
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  private loadPlayerInViewAndInitPlayer(): void {
    this.scriptLoadProperties = {isLoading: false};
    this.isPlayerLoading = true;
    this.triggerLoadPlayer = true;
  }

  private initializePlayer(): void {
    const kalturaMediaEmbed = ConfigurationUtils.generateKalturaMediaEmbedData<VideoPlayerComponent>(
      this.internalConfig, {readyCallback: this.onPlayerReady, thisArg: this});

      this.window.kWidget.embed(kalturaMediaEmbed);
  }

  private onPlayerReady(playerId: string): void {
    this.ngZone.run(() => {
      if (playerId === this.defaultPlayerId) {
        this.setPlayerReference(playerId);

        if (!!this.embedContainerRef?.kBind) {
          this.setPlayerMediaEventsListeners();
        }

        this.isPlayerLoading = false;
        this.changeDetectorRef.markForCheck();
      }
    });
  }

  private setPlayerReference(playerId: string): void {
    const kalturaEmbedElement = this.window.document
      .querySelector<KalturaEmbedElement>(`#${playerId}.player-embed`);

    const kalturaIframeEmbed = kalturaEmbedElement
      .querySelector<HTMLIFrameElement>(`#${playerId}_ifp`);

    if (kalturaIframeEmbed) {
      const iFrameDocument = kalturaIframeEmbed.contentDocument || kalturaIframeEmbed.contentWindow.document;
      this.kalturaPlayerElementRef = iFrameDocument
        .querySelector<KalturaPlayerElement>(`#${playerId}.mwEmbedPlayer`);
    }

    /**
     * When using the player embed, a new element is created on top of an exiting angular element which
     *  replaces the angular attributes.
     * One of which is the host ID of this element.
     * To retain this ID, grab the attributes from the existing element and replace them on the newly
     * created element.
     * Note: This is mainly due to ViewEncapsulation
     */
    const embedAttributes = this.playerEmbedElement.nativeElement.attributes;
    // tslint:disable-next-line:prefer-for-of
    for (let idx = 0; idx < embedAttributes.length; idx++) {
      const attribute = embedAttributes[idx];

        if (attribute.name !== 'class' && attribute.name !== 'id') {
          kalturaEmbedElement.setAttribute(attribute.name, attribute.value);
          kalturaEmbedElement.firstElementChild.setAttribute(attribute.name, attribute.value); // iframe element
        }
    }

    this.embedContainerRef = kalturaEmbedElement;
  }

  private setPlayerMediaEventsListeners(): void {
    this.kalturaEvents.forEach(key => {
      const rawEvent = KalturaPlayerEvents[key];
      const event = `${rawEvent}.${this.defaultPlayerId}`;

      this.embedContainerRef.kBind(event, data => {
        if (rawEvent === KalturaPlayerEvents.onChangeMedia && !!this.internalConfig.flashvars?.ks) {
          this.setKalturaPlayerAttribute('servicesProxy.kalturaClient', 'ks', this.internalConfig.flashvars.ks);
        }

        this.playerMediaStateSubject.next({
          event: rawEvent,
          data,
          playerReference: this.kalturaPlayerElementRef
        });
      });
    });
  }

  private handleScriptLoadError(): void {
    if (isDevMode()) {
      console.log('Error while loading scripts from Kaltura.');
    }

    this.scriptLoadProperties = {
      isLoading: false,
      error: {scriptLoadError: true}
    };
  }

  private destroyKWidget(): void {
    if (this.window.kWidget) {
      this.window.kWidget.destroy(this.internalConfig.targetId);
    }
  }

  private setConfigOnMediaChange(ks: string, entryId: string): void {
    const flashvars = this.internalConfig.flashvars || {};

    this.internalConfig = {
      ...this.internalConfig,

      flashvars: {
        ...flashvars,
        ks
      },
      entryId
    };
  }

  private listenToPlayerEmitters(): void {
    if (this.triggerPlayerSendBackToLive) {
      this.subSink.add(this.triggerPlayerSendBackToLive
        .subscribe((sendBackToLive: boolean) => {
          if (sendBackToLive) {
            this.triggerMediaSendBackToLive();
          }
        })
      );
    }

    if (this.mediaPlayerPlayEmitter) {
      this.subSink.add(this.mediaPlayerPlayEmitter
        .subscribe((doPause) => this.triggerMediaPlay(doPause))
      );
    }

    if (this.triggerPlayerMute) {
      this.subSink.add(this.triggerPlayerMute
        .subscribe((mute: boolean) => this.triggerMediaMute(mute))
      );
    }

    if (this.triggerPlayerSeek) {
      this.subSink.add(this.triggerPlayerSeek
        .subscribe((seekInSeconds: number) => this.triggerMediaSeek(seekInSeconds))
      );
    }

    if (this.triggerPlayerFullscreen) {
      this.subSink.add(this.triggerPlayerFullscreen
        .subscribe((fullScreen: boolean) => this.triggerMediaFullscreen(fullScreen))
      );
    }

    if (this.triggerPlayerVolumeChange) {
      this.subSink.add(this.triggerPlayerVolumeChange
        .subscribe((volume: number) => this.triggerVolumeChange(volume))
      );
    }

    if (this.triggerPlayerChromeCast) {
      this.subSink.add(this.triggerPlayerChromeCast
        .subscribe((toggle: boolean) => {
          this.ngZone.runOutsideAngular(args => {
            this.triggerChromeCast(toggle);
          });
        })
      );
    }

    if (this.triggerChangeBitRateSource) {
      this.subSink.add(this.triggerChangeBitRateSource.subscribe((source) => {
        this.ngZone.runOutsideAngular(args => {
          this.kalturaPlayerElementRef.switchSrc(source);
        });
      }));
    }
  }

  /**
   * Trigger volume change.
   * @param volume - A value from 0 to 100
   */
  private triggerVolumeChange(volume: number): void {
    this.sendDataToKalturaPlayer(KalturaPlayerEvents.volumeChanged, volume);
  }

  private triggerChromeCast(toggle: boolean): void {
    if (this.kalturaPlayerElementRef?.plugins?.chromecast?.toggleCast) {
      (this.kalturaPlayerElementRef.plugins.chromecast as PlayerChromecastFlashVar).toggleCast();
    }
  }

  private triggerMediaChange(data: PlayerChangeMedia): void {
    this.setConfigOnMediaChange(data.ks, data.entryId);
    this.sendDataToKalturaPlayer(KalturaPlayerEvents.changeMedia, {entryId: data.entryId});
  }

  private triggerMediaPlay(doPause?: boolean): void {
    this.sendDataToKalturaPlayer(doPause
      ? KalturaPlayerEvents.doPause
      : KalturaPlayerEvents.doPlay);
  }

  private triggerMediaFullscreen(fullScreen: boolean): void {
    this.sendDataToKalturaPlayer(fullScreen
      ? KalturaPlayerEvents.openFullScreen
      : KalturaPlayerEvents.closeFullScreen);
  }

  private triggerMediaMute(mute: boolean): void {
    if (mute) {
      const previousVolumeFromPlayerCookie = this.cookieService.get(VideoPlayerComponent.cookieVolumeStorage);

      if (previousVolumeFromPlayerCookie && previousVolumeFromPlayerCookie !== '0') {
        // Convert value to decimals as volume is calculated from 0 to 1.
        this.previousVolume = Number.parseInt(previousVolumeFromPlayerCookie, 10) / 100;
      } else {
        this.previousVolume = VideoPlayerComponent.volumeDefaultValue;
      }

      this.sendDataToKalturaPlayer(KalturaPlayerEvents.volumeChanged, 0);
    } else {
      this.sendDataToKalturaPlayer(KalturaPlayerEvents.volumeChanged, this.previousVolume);
    }
  }

  /**
   * Trigger media seek in the given seconds.
   * @param seekInSeconds - The time in seconds of where to seek.
   */
  private triggerMediaSeek(seekInSeconds: number): void {
    const value = this.embedContainerRef && (this.embedContainerRef as any).evaluate
      ? Number.parseInt((this.embedContainerRef as any).evaluate('{video.player.currentTime}'), 10) + seekInSeconds
      : seekInSeconds;

    this.sendDataToKalturaPlayer(KalturaPlayerEvents.doSeek, value);
  }

  private triggerMediaSendBackToLive(): void {
    this.sendDataToKalturaPlayer(KalturaPlayerEvents.backToLive);
  }

  private sendDataToKalturaPlayer(eventName: string, data?: unknown): void {
    if (this.embedContainerRef && this.embedContainerRef.sendNotification) {
      this.embedContainerRef.sendNotification(eventName, data);
    }
  }

  private setKalturaPlayerAttribute(object: string, property: string, value: string): void {
    if (this.embedContainerRef && this.embedContainerRef.setKDPAttribute) {
      this.embedContainerRef.setKDPAttribute(object, property, value);
    }
  }

  private isOnTheSamePlayer(playerConfig: VideoPlayerConfig): boolean {
    return this.videoPlayerConfig.partnerId === playerConfig.partnerId
          && this.videoPlayerConfig.uiConfId === playerConfig.uiConfId
          && this.videoPlayerConfig.kalturaBaseUrl === playerConfig.kalturaBaseUrl;
  }

  private destroyScriptAndTriggerInit(playerConfig: VideoPlayerConfig): void {
    this.destroyKWidget();
    this.scriptTagService.destroyScript(this.internalConfig.kalturaPlayerJsUrl);
    this.triggerPlayerInit(playerConfig);
  }
}
