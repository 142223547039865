import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WINDOW_PROVIDERS } from '../../providers/window-provider';
import { ScriptTagService } from '../../services/script-tag.service';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { VodCardComponent } from './vod-card/vod-card.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import {CookieService} from 'ngx-cookie-service';

@NgModule({
  declarations: [ 
    VideoPlayerComponent, 
    //VodCardComponent 
  ],
  imports: [
    CommonModule,
    LazyLoadImageModule
  ],
  exports: [
    VideoPlayerComponent,
    //VodCardComponent
  ],
  providers: [
    WINDOW_PROVIDERS,
    CookieService,
    ScriptTagService
  ]
})
export class VideoPlayersModule {
  static forRoot(): ModuleWithProviders<VideoPlayersModule> {
    return {
      ngModule: VideoPlayersModule,
      providers: [
        WINDOW_PROVIDERS,
        CookieService,
        ScriptTagService
      ]
    };
  }
}
