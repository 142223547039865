import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ChangeDetectionStrategy,
  AfterViewChecked,
  Output,
  OnDestroy, EventEmitter, ChangeDetectorRef
} from '@angular/core';
import Flickity, {Options} from 'flickity';
import {FlickityUtils} from '../../utils/flickity-utils';
import {SubSink} from 'subsink';
import {GetMetaData} from '../../models/cards/cards.type';

@Component({
  selector: 'sa-carousel',
  templateUrl: './carousel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselComponent implements OnInit, OnDestroy, AfterViewChecked {

  // todo: properly handle content update/insert on flickity
  // todo: simplify input and outputs.
  //  - Card data should not be in this component however it should give a simple card.
  // todo: provide template ref
  /*
  * input data you want to show or hide
  */
  @Input() getMetaData: GetMetaData<unknown>;
  @Input() layoutCard: string;
  @Input() isCarouselTitle: boolean;
  @Input() isCarouselSubtitle: boolean;
  @Input() isCategory: boolean;
  @Input() isRuntime: boolean;
  @Input() isEntitlement: boolean;
  @Input() isDateTimeImage: boolean;
  @Input() isDateTime: boolean;
  @Input() isViewAll: boolean;

  @Input() carouselTitle: string;
  @Input() carouselSubtitle: string;
  @Input() path: string;
  @Input() basePath: string;
  @Input() viewAll = 'viewAll';
  @Input() viewAllUrl = '';
  @Input() viewAllUrlParams: { [key: string]: string };
  @Input() selectItem: any;

  @Input() isName: boolean;
  @Input() isCompetition: boolean;
  @Input() isDate: boolean;
  @Input() formatDate: string;
  @Input() truncateText: number;
  @Input() endNumberCards: number;
  @Input() startNumberCards: number = 0;

  @Input() labelLiveButton: string;
  @Input() labelLiveTag: string;

  @Input() buttonName: string;
  @Input() isDescription: boolean;
  @Input() isTitle: boolean;
  @Input() isSubTitle: boolean;
  @Input() typeCarousel: string;

  @ViewChild('carouselContainer') carouselContainerRef;

  @Input() flickityConfiguration: Options;
  @Input() flickityControl: EventEmitter<boolean>; // an emitter to be able to destroy flickity at will.
  @Input() carouselData: unknown[];

  private triggerFlickityInit: boolean;
  private carousel: Flickity;
  private subSink: SubSink;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    this.triggerFlickityInit = true;
    this.subSink = new SubSink();
    this.flickityConfiguration = {};
    this._configuration = {
      enableFlickity: true,
    };
    this.carouselData = [];
  }

  private _configuration: {
    enableFlickity: boolean;
  };

  @Input() set configuration(value: { enableFlickity: boolean }) {
    this._configuration = value;
    this.triggerFlickityInit = value.enableFlickity;
  }

  public getPlayerPath(path: string, idx: number): string {
    return `${path}/${idx}/feed`;
  }

  ngOnInit() {

    if (this.flickityControl) {
      this.subSink.add(
        this.flickityControl.subscribe((enable) => {
          if (enable) {
            this.triggerFlickityInit = true;
          } else {
            this.destroyFlickity();
          }

          this.changeDetectorRef.markForCheck();
        })
      );
    }

    this.triggerFlickityInit = this._configuration.enableFlickity;
  }

  ngOnDestroy(): void {
    this.destroyFlickity();
    this.subSink.unsubscribe();
  }

  ngAfterViewChecked() {
    if (this.triggerFlickityInit && this.carouselData && this.carouselData.length) {
      // initialize carousel after all items are rendered
      this.carousel = new Flickity(this.carouselContainerRef.nativeElement, {
        groupCells: true,
        contain: true,
        prevNextButtons: true,
        pageDots: false,
        friction: 0.10, // default: 0.28. Higher friction = stickier and less bouncy
        selectedAttraction: 0.005, // default: 0.025. Higher attraction makes the slider move faster
        ...(this.flickityConfiguration ? this.flickityConfiguration : {})
      });

      FlickityUtils.flickityBugFix(this.carousel, this.carouselContainerRef.nativeElement, (window as any).navigator);
      this.triggerFlickityInit = false;
    }
  }

  @Output() onClick = new EventEmitter<string>();

  onSelectGame(item){
    this.selectItem = this.selectItem ==  item.feed ? '' : item.feed;
    if(this.selectItem !=  item.feed)
      item = '';
    this.onClick.emit(item);
  }

  private destroyFlickity(): void {
    if (this.carousel) {
      this.carousel.destroy();
      this.carousel = null;
    }
  }

}
