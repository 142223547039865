import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

export interface FilterDurationData {
  label: string;
  value: string;
  selected: boolean;

  [key: string]: any;
}

@Component({
  selector: 'sa-filter-duration',
  templateUrl: './filter-duration.component.html',
  styleUrls: ['./filter-duration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterDurationComponent {
  @Output() cardClick: EventEmitter<FilterDurationData>;
  @Input() title: string;
  private previousSelectedItem: FilterDurationData;

  constructor() {
    this._data = [];
    this.cardClick = new EventEmitter<FilterDurationData>();
  }

  private _data: FilterDurationData[];

  get data(): FilterDurationData[] {
    return this._data;
  }

  @Input() set data(value: FilterDurationData[]) {
    this._data = value;

    if (value?.length) {
      this.previousSelectedItem = value.find(filterDuration => filterDuration.selected);
    }
  }

  onValueClick(item: FilterDurationData): void {
    if (this.previousSelectedItem && this.previousSelectedItem.value === item.value) {
      item.selected = false;
      this.previousSelectedItem = null;
    } else {
      if (this.previousSelectedItem) {
        this.previousSelectedItem.selected = false;
      }

      item.selected = true;
      this.previousSelectedItem = item;
    }

    this.cardClick.emit(item);
  }
}
