import {Component, OnInit, Input, ChangeDetectorRef, EventEmitter, Output, Inject, LOCALE_ID} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AmgEvent, AmgFixture } from '../../../models/fixture/amg.fixture';
import { FixtureService } from '../../../services/fixture.service';
import { FixtureBaseComponent } from '../../../utils/fixture/fixture-base.component';
import { PlayerUtils } from '../../../utils/player-utils';

@Component({
  selector: 'sa-card-fixture',
  templateUrl: './card-fixture.component.html'
})
export class CardFixtureComponent extends FixtureBaseComponent implements OnInit {
  public static readonly mediaType = 'video';
  defaultImage = '/assets/images/placeholder.jpg';
  date:any;
  link: string;

  @Input() isCalendarEvents: boolean;
  @Input() calendarButtonName: string;
  @Input() indexCalendar: number;
  @Input() dataCalendar: any;

  @Input() fixtureType: string;
  @Input() fixtureData: AmgFixture | AmgEvent;
  @Input() formatDate: string = 'dd.MM.yyyy';
  @Input() labelLiveButton: string;
  @Input() labelLiveTag: string;

  @Input() isCompetition: boolean;
  @Input() isTeamType: boolean;
  @Input() isDate: boolean;

  @Input() path: string;
  @Input() basePath: string = '/live';


  constructor(
    protected http: HttpClient,
    protected fixtureService: FixtureService,
    protected cd: ChangeDetectorRef,
    @Inject(LOCALE_ID) public locale: string
  ) {
    super(http, fixtureService, cd);
  }

  ngOnInit() {
    this.init();
    this.date = this.getFormattedDate(this.formatDate, this.locale);


      const path = PlayerUtils.encodeUrlParamsForPlayer(`${this.getEntryId(CardFixtureComponent.mediaType)}`);
      this.link = `${this.basePath}/${path}`;
  }

  getTime(): string {
    return this.getFormattedDate('HH:mm', this.locale) + ' Uhr';
  }

  public getLink(mediaType: string): string {

    const path = PlayerUtils.encodeUrlParamsForPlayer(`${this.getEntryId(mediaType)}`);

    return `/live/${path}`;
  }

  @Output() onEventCalendar = new EventEmitter<string>();

  onCalendarEvent(){
    this.onEventCalendar.emit();
  }

}
