import {HttpClient} from '@angular/common/http';

import {Observable, ReplaySubject} from 'rxjs';
import {Injectable} from '@angular/core';
import {
  AnywhereData,
  AnywhereResponse,
  AnywhereSectionData,
  AnywherePagingData
} from '../models/anywhere/anywhere-api.type';
import {
  AnywhereFilters
} from '../models/anywhere/anywhere.type';
import {CardPlaylistData} from '../models/playlist/playlist';
import {AnywhereUtils} from '../utils/anywhere-utils';

@Injectable({
  providedIn: 'root'
})
export class CloudMatrixService<AnywhereMetaData> {

  protected specificCard: CardPlaylistData<AnywhereMetaData>;
  protected nextPagingData: AnywherePagingData;
  protected urlFeed: string;
  //protected playlistScrollBar: SimpleBar;
  protected playlistContentSize: string = null;
  protected anywhereData: AnywhereData<AnywhereMetaData>;
  protected noCache: boolean;
  protected pagingData: string;
  private cache: { [url: string]: ReplaySubject<AnywhereData<AnywhereMetaData>>; };

  constructor(private httpClient: HttpClient) {

    this.cache = {};
  }


  getItemData(
    anywhereData: AnywhereData<AnywhereMetaData>,
    data: AnywhereResponse<AnywhereMetaData>
  ): AnywhereData<AnywhereMetaData> {

    let newAnywhereData: AnywhereData<AnywhereMetaData>;
    if ((data as AnywhereSectionData<AnywhereMetaData>).sections) {

      newAnywhereData = (data as AnywhereSectionData<AnywhereMetaData>).sections[0];

    } else if ((data as AnywhereData<AnywhereMetaData>).itemData) {

      newAnywhereData = (data as AnywhereData<AnywhereMetaData>);
    }

    return {
      ...newAnywhereData,
      itemData: (anywhereData && anywhereData.itemData)
        ? [
          ...anywhereData.itemData,
          ...newAnywhereData.itemData
        ]
        : newAnywhereData.itemData
    };
  }

  triggerApiCall(
    apiUrl: string,
    pageSize: string,
    anywhereData?: AnywhereData<AnywhereMetaData>,
    filters?: AnywhereFilters,
    noCache?: boolean
  ): Observable<AnywhereData<AnywhereMetaData>> {
    let noMoreData = false;

    let anywhereUrl = '';

    if (anywhereData && anywhereData.pagingData) {
      // load next page
      if (AnywhereUtils.isMoreDataAvailable(anywhereData.pagingData)) {

        // tslint:disable-next-line:max-line-length
        anywhereUrl = AnywhereUtils.generateAnywhereUrl(apiUrl, this.generateAnywhereSearchQuery(filters), pageSize, anywhereData.pagingData.pageIndex + 1);

      } else {
        noMoreData = true;
      }
    } else {
      // load first page
      anywhereUrl = AnywhereUtils.generateAnywhereUrl(apiUrl, this.generateAnywhereSearchQuery(filters), pageSize);
    }

    const cacheKey = anywhereUrl;

    if (noCache || !this.cache[cacheKey]) {

      if (noMoreData) {

        this.cache[cacheKey].next(anywhereData);
        // this.cache[fixtureUrl].complete();

      } else {

        this.cache[cacheKey] = new ReplaySubject<AnywhereData<AnywhereMetaData>>(1);

        this.httpClient.get<AnywhereResponse<AnywhereMetaData>>(anywhereUrl)
          .subscribe(data => {

            if (data) {

              anywhereData = this.getItemData(anywhereData, data);

              this.cache[cacheKey].next(anywhereData);
            } else {

              this.cache[cacheKey].next({
                itemData: []
              })
            }

          }, error => {

            this.cache[cacheKey].next({
              error: true
            })
          });
      }
    }
    return this.cache[cacheKey].asObservable();
  }

  private generateAnywhereSearchQuery(data: { [key: string]: (string | { [key: string]: string }) }): { query?: string } {
    return data && Object.keys(data).length > 0
      ? {query: `(${this.generateSearchQueryFilters(data, ' AND ')})`}
      : {};
  }

  // tslint:disable-next-line:max-line-length
  private generateSearchQueryFilters(data: { [key: string]: (string | { [key: string]: string }) }, operatorKey: (' AND ' | ' OR ')): string {
    const queryParam: string[] = [];

    Object.keys(data).forEach(key => {
      const value = data[key];

      if (typeof value === 'string') {
        queryParam.push(`${key}:(${value})`);
      } else {
        queryParam.push(`(${this.generateSearchQueryFilters(value, ' OR ')})`);
      }
    });

    return queryParam.join(operatorKey);
  }

}
