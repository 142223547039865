import { Component, Input, OnInit, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'sa-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterDropdownComponent implements OnInit {
  @Input() dropdownTitle:string;
  @Input() isDropdownTitle:boolean;
  @Input() selectedItem = null;
  @Output() filter = new EventEmitter<string>();
  @Input() placeholder = "Select value";
  @Input() searchable: boolean;
  @Input() clearable: boolean;
  @Input() disabled: boolean;
  @Input() items: any;

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() {}

  onFilterChange(value){
    this.filter.emit(value);
    this.cd.markForCheck();
  }

}
