import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {AccordionData, CategoryData} from '../../accordion/accordion.type';

@Component({
  selector: 'sa-filter-grouping',
  templateUrl: './filter-grouping.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterGroupingComponent {
  @Input() placeholder: string;
  @Input() dropdownTitle: string;
  @Input() isDropdownTitle: boolean;
  @Input() searchable: boolean;
  clearable = false;

  @ViewChild('ngSelectRef') ngSelectRef;
  @Input() selectedItemLabel: string;
  @Input() data: AccordionData[];
  @Output() triggerFilter = new EventEmitter<CategoryData | string>();

  public itemsData = [ {category: '', competition: ''} ]; // Important for init ng-select and add accordion

  constructor() {
    this.data = [];
  }

  onItemClick(item: CategoryData | string): void {
    this.data.forEach(value => {
      value.selected = false;
    });
    this.ngSelectRef.close();
    this.selectedItemLabel = (typeof item === 'string')
      ? item
      : item.categoryLabel;
    this.triggerFilter.emit(item);
  }
}

