<div [class.opened]="isOpened" class="modal-search">
  <div class="modal-content">
    <div (click)="closeModel()" class="close-btn"></div>
    <div class="box-data">
      <div class="content">
        <sa-search
          (searchTrigger)="onValueSearch($event)"
          [fieldValue]="fieldvalue"
          [iconSearchColor]="iconSearchColor"
          [placeholder]="placeholder"
          [searchLabel]="searchLabel">
        </sa-search>
      </div>
    </div>
  </div>
</div>
