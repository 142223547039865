import {WpImage} from 'components-lib';

export class WordpressUtils {
  public static getImageData(image: WpImage): { src: string; srcset: string; } {
    return {
      src: image.url,
      srcset: WordpressUtils.generateSrcSet(image)
    };
  }

  public static generateSrcSet(image: WpImage): string {
    const sources: string[] = [
      `${image.url} ${image.width}w` // Original source
    ];

    if (image.sizes.thumbnail) {
      sources.push(`${image.sizes.thumbnail.url} ${image.sizes.thumbnail.width}w`);
    }

    if (image.sizes.medium) {
      sources.push(`${image.sizes.medium.url} ${image.sizes.medium.width}w`);
    }

    if (image.sizes.medium_large) {
      sources.push(`${image.sizes.medium_large.url} ${image.sizes.medium_large.width}w`);
    }

    if (image.sizes.large) {
      sources.push(`${image.sizes.large.url} ${image.sizes.large.width}w`);
    }

    if (image.sizes['1536x1536']) {
      sources.push(`${image.sizes['1536x1536'].url} ${image.sizes['1536x1536'].width}w`);
    }

    if (image.sizes['2048x2048']) {
      sources.push(`${image.sizes['2048x2048'].url} ${image.sizes['2048x2048'].width}w`);
    }

    return sources.join(', ');
  }


  public static getValueByPath<T>(wpPath: string, pageData): T {

    const wpPathArray = wpPath.split('/');
    let data = pageData;

    wpPathArray.forEach((item) => {

      data = data[item];
    });

    return data;
  }
}
