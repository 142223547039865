<div class="card-player-container">
  <a [routerLink]="link" [className]='className'></a>
  <div class="image-container">
    <div class="image lazyload-image" [lazyLoad]="linkImg"></div>
  </div>
  <div class="card-info">
    <div class="content-info">
      <h3 class="name">
        {{name}}  <span *ngIf="isSurname" class="surname" [textContent]="surname"></span>
      </h3>
    </div>
  </div>
</div>
