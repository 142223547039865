import {Injectable, isDevMode} from '@angular/core';
import {WordpressService} from 'wordpress-lib';
import {environment} from '../../../environments/environment';
import {WpImage} from 'components-lib';
import {MenuItem, SubLink, SubMenuItem} from '../models/wordpress/common-labels.type';
import {WordpressUtils} from '../../utils/wordpress-utils';
import {BehaviorSubject, Observable} from 'rxjs';
import {CharacterUtils} from '../../utils/character-utils';

export interface PageData {
  id: number;
  url: string;
  fixtureFilter: string;
  vodUrl: string;
  carouselUrl: string;
  image: { src: string; srcset: string; };
  label: string;
  wpPath: string;
}

@Injectable({
  providedIn: 'root'
})
export class TeamLandingPageService {
  private menuMapping: {
    [pageRoute: string]: PageData;
  };
  private menuIndexCounter: number;
  private commonDataLoaded: BehaviorSubject<boolean>;

  public static generatePageRoute(mainLink: string, subMenuMainLabel: string, subMenuLabel: string): string {
    return `${mainLink}/${CharacterUtils.parseLabel(subMenuMainLabel)}/${CharacterUtils.parseLabel(subMenuLabel)}`;
  }

  constructor(private wordpressService: WordpressService) {
    this.menuMapping = {};
    this.menuIndexCounter = 0;
    this.commonDataLoaded = new BehaviorSubject<boolean>(false);

    const commonDataUrl = `${environment.wordpress.api}${environment.wordpress.commonData}`;
    this.wordpressService.getWordpressData<any>(commonDataUrl)
      .subscribe(commonData => {
        if (commonData && commonData.menu) {
          this.processHeaderMenu(commonData.menu as MenuItem[]);
          this.commonDataLoaded.next(true);

          if (isDevMode()) {
            // tslint:disable-next-line:no-console
            console.info(this.menuMapping);
          }
        }
      });
  }

  public getMenuById(id: number): PageData {
    const menuKey = Object.keys(this.menuMapping)
      .find(key => this.menuMapping[key].id === id);

    return (menuKey)
      ? this.menuMapping[menuKey]
      : null;
  }

  public isCommonDataLoaded(): Observable<boolean> {
    return this.commonDataLoaded.asObservable();
  }

  public generateTeamLandingRouterPath(menuItem: MenuItem, subMenuItem: SubMenuItem, subLink: SubLink): string {
    const mainLink = menuItem.link;
    const subMenuMainLabel = subMenuItem.single_menu.slug || subMenuItem.single_menu.title;
    const subMenuLabel = subLink.slug || subLink.label;

    return TeamLandingPageService.generatePageRoute(mainLink, subMenuMainLabel, subMenuLabel);
  }

  public getRouteData(route: string): PageData {
    return this.menuMapping[route];
  }

  public getMatchingRoutes(subRoute: string): PageData[] {
    return Object.keys(this.menuMapping)
      .filter(key => key.includes(subRoute))
      .map(key => this.menuMapping[key]);
  }

  private processHeaderMenu(menuItem: MenuItem[]): void {

    menuItem.forEach((menuWithSubMenu, menuIndex) => {

      if (menuWithSubMenu.submenu) {
        const mainLink = menuWithSubMenu.link;

        (menuWithSubMenu.submenu as SubMenuItem[]).forEach((subMenuItem, subMenuIndex) => {
          const subMenuMainLabel = subMenuItem.single_menu.slug || subMenuItem.single_menu.title;

          subMenuItem.single_menu.sub_link.forEach((value, subLinkIndex) => {
            const subMenuSlug = value.slug || value.label.split(' ').join('-');
            const subMenuLabel = value.label || value.slug.split('-').join(' ');
            const pageFilter = value.param;
            const pageImage = value.image;
            const vodUrl = value.vod_url;
            const carouselUrl = value.carousel_url;
            const wpPath = `${menuIndex}/submenu/${subMenuIndex}/single_menu/sub_link/${subLinkIndex}`

            const pageRoute = TeamLandingPageService.generatePageRoute(mainLink, subMenuMainLabel, subMenuSlug);

            this.addMenuMapping(pageRoute, subMenuLabel, pageImage, pageFilter, vodUrl, carouselUrl, wpPath);
          })
        });
      }
    })

  }

  private addMenuMapping(pageRoute: string,
                         label: string,
                         image: (WpImage | boolean),
                         fixtureFilter: string,
                         vodUrl: string,
                         carouselUrl: string,
                         wpPath: string): void {
    this.menuIndexCounter = this.menuIndexCounter + 1;

    this.menuMapping = {
      ...this.menuMapping,
      [pageRoute]: {
        url: pageRoute,
        image: image ? WordpressUtils.getImageData(image as WpImage) : null,
        id: this.menuIndexCounter,
        label,
        fixtureFilter,
        vodUrl,
        carouselUrl,
        wpPath: `/common_field?slug=common-fields|menu/${wpPath}`
      }
    }
  }
}
