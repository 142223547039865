
import { isPlatformBrowser } from '@angular/common';
import { Component, Input, Inject, PLATFORM_ID } from '@angular/core';
//import { slide_inout_animation } from './gdpr.animation';

import {
  transition,
  animate,
  style,
  group,
  state,
  trigger,
} from '@angular/animations';

export const SlideInOutAnimation = [
  trigger('slideInOut', [
    state(
      'in',
      style({
        opacity: '1',
        visibility: 'visible',
      })
    ),
    state(
      'out',
      style({
        opacity: '0',
        visibility: 'hidden',
      })
    ),
    transition('in => out', [
      group([
        animate(
          '400ms ease-in-out',
          style({
            opacity: '0',
          })
        ),
        animate(
          '600ms ease-in-out',
          style({
            'max-height': '0px',
          })
        ),
        animate(
          '700ms ease-in-out',
          style({
            visibility: 'hidden',
          })
        ),
      ]),
    ]),
    transition('out => in', [
      group([
        animate(
          '1ms ease-in-out',
          style({
            visibility: 'visible',
          })
        ),
        animate(
          '600ms ease-in-out',
          style({
            'max-height': '90px',
          })
        ),
        animate(
          '800ms ease-in-out',
          style({
            opacity: '1',
          })
        ),
      ]),
    ]),
  ]),
];

@Component({
  selector: 'sa-gdpr',
  templateUrl: './gdpr.component.html',
  styleUrls: ['./gdpr.component.scss'],
  animations: [SlideInOutAnimation],
})
export class GdprComponent {
  animationState = 'out';

  @Input() key = '';
  @Input() policy_link = 'integritetspolicy';

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      // Client only code.
      if (!window.localStorage.getItem(this.key)) {
        this.animationState = 'in';
      }
    }
  }

  setGdpr() {
    if (isPlatformBrowser(this.platformId)) {
      window.localStorage.setItem(this.key, 'yes');
      this.animationState = 'out';
    }
  }
}
