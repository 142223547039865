import { 
  Component, 
  OnInit, 
  Input,   
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';

@Component({
  selector: 'sa-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {

  @Input() isTitleForm: boolean;
  @Input() titleForm: string;
  @Input() isUsernameLabel: boolean;
  @Input() isPasswordLabel: boolean;
  @Input() usernameLabel: string;
  @Input() passwordLabel: string;

  @Input() passwordPlaceHolder: string = 'Password';
  @Input() emailPlaceHolder: string = 'Email Address';
  @Input() forgotPwd: string = 'Forgot Password?';
  @Input() forgotPwdUrl: string;
  @Input() nameLoginBtn: string = 'Login';
  @Input() errorEmailRequired: string = "Email is required";
  @Input() invalidEmail: string = "Invalid Email";
  @Input() errorPwdRequired: string = "Password is required";
  @Input() errorPwdLength: string = "Password need to be min. 6";
 
  @Input() passwordLength: number = 6;
  @Input() errorServer:string;
  
  loginForm: FormGroup;
  
  /*@Input() formSubmissionMessageResponse = {
    success: '',
    error: ''
  };*/

  constructor(
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      emailaddress: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(this.passwordLength)
      ]))
    });
  }

  protected validateAllFormFields() {
    Object.keys(this.loginForm.controls).forEach(field => {
      const control = this.loginForm.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields();
      }
    });
  }

  @Output() submit = new EventEmitter<string>();
  
  formSubmit(ev){
    ev.stopPropagation();
    ev.preventDefault();
    if (this.loginForm.dirty && this.loginForm.valid)
        this.submit.emit(this.loginForm.value);
    else
        this.validateAllFormFields();
  }

}
