import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, BehaviorSubject} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class WordpressService {
  private cache: { [url: string]: BehaviorSubject<unknown> };

  constructor(private httpClient: HttpClient) {
    this.cache = {};
  }

  /**
   * Grabs the data from the memory cache if available, else it will trigger a call and retrieves the data.
   * @param pagePath WP page path
   */
  public getWordpressData<D>(pagePath: string): Observable<D> {
    return this.getData<D>(pagePath);
  }

  private getData<T>(url: string): Observable<T> {
    if (!this.cache[url]) {
      this.cache[url] = new BehaviorSubject<T>(null);

      this.httpClient.get(url).subscribe(value => {
        this.cache[url].next(value);
      }, error => {
        this.cache[url].next([]);
      });
    }

    return this.cache[url].asObservable() as Observable<T>;
  }
}
