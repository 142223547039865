import {NgModule} from '@angular/core';
import {RouterModule, Routes, UrlSegment} from '@angular/router';
import {TeamLandingPageResolver} from './core/services/team-landing-page.resolver';


const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'live', pathMatch: 'full',
    loadChildren: () => import('./pages/fixtures/fixtures.module').then(m => m.FixturesModule)
  },
  {
    path: 'oefbthek',
    loadChildren: () => import('./pages/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    path: 'live', // Video - Live
    loadChildren: () => import('./pages/video/video-live/video-live.module').then(m => m.VideoLiveModule)
  },
  {
    path: 'video', // Video - VOD
    loadChildren: () => import('./pages/video/video-vod/video-vod.module').then(m => m.VideoVodModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faqs/faqs.module').then(m => m.FaqsModule)
  },
  {
    path: 'view',
    loadChildren: () => import('./pages/view/view.module').then(m => m.ViewModule)
  },
  // Route for: teams, league, amateurs
  {
    matcher: (url: UrlSegment[]) => {
      return (url.length > 0
        && (
          url[0].path === 'teams' ||
          url[0].path === 'ligen-bewerbe' ||
          url[0].path === 'amateurs' ||
          url[0].path === 'fussballaktiv' ||
          url[0].path === 'oefb'
        ))
        ? {consumed: url}
        : null;
    },
    loadChildren: () => import('./pages/team-landing/team-landing.module').then(m => m.TeamLandingModule),
    resolve: {
      teamLandingData: TeamLandingPageResolver
    }
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'disabled',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
