<div class="input-search-field-container" [ngClass]="{ 'focused': isFocused }">
    <div class="label-content" *ngIf="searchLabel">
        <label class="input-search-label" [textContent]="searchLabel"></label>
    </div>
    <div class="field-container">
        <svg xmlns="http://www.w3.org/2000/svg" width="19.797" height="19.796" viewBox="0 0 19.797 19.796">
            <path id="Path_1660" data-name="Path 1660" d="M1783.954,110.463l5.224,5.224-1.286,1.285-5.223-5.223a8.186,8.186,0,1,1,1.285-1.286m-6.392,1.256a6.363,6.363,0,1,0-6.362-6.363,6.363,6.363,0,0,0,6.362,6.363"
            transform="translate(-1769.381 -97.176)"
            [attr.fill]="iconSearchColor"/>
        </svg>
        <input type="search"
               [attr.placeholder]="placeholder"
               [(ngModel)]="fieldValue"
               (search)="handleSearch($event)"
               (keyup)="handleKeyUp($event)"
               (focusin)="onFocus(true)"
               (focusout)="onFocus(false)">
        <div></div>
    </div>
</div>
