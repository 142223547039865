<div *ngIf="isCarouselTitle || isCarouselSubtitle || isViewAll" class="title-container">
    <div *ngIf="isCarouselTitle" class="content-title"><h2 class="title" [textContent]="carouselTitle"></h2></div>
    <div *ngIf="isCarouselSubtitle" class="content-subtitle"><h3 class="subtitle" [textContent]="carouselSubtitle"></h3></div>
    <div *ngIf="isViewAll" class="link">
      <a [routerLink]="viewAllUrl" [queryParams]="viewAllUrlParams" [innerText]="viewAll"></a>
    </div>
</div>
<div #carouselContainer class="carousel-container">
  <ng-container [ngSwitch]="layoutCard">
    <!--Game Card-->
    <ng-container *ngSwitchCase = "'cardGame'">
      <div *ngFor="let item of carouselData; let i = index" class="item" (click)="onSelectGame(item)" [ngClass]="{'active': selectItem == item.feed}">
        <sa-card-game
          [componentDataGame] = "item">
        </sa-card-game>
      </div>
    </ng-container>
    <!--Player Card-->
    <ng-container *ngSwitchCase = "'cardPlayer'">
      <div *ngFor="let item of carouselData; let i = index" class="item">
        <sa-card-player
          [isName] = 'isName'
          [componentDataPlayer] = "item"
          [path]="getPlayerPath(path, i)"
          [basePath]="basePath">
        </sa-card-player>
      </div>
    </ng-container>
    <!--Video Card-->
    <ng-container *ngSwitchCase = "'cardVideo'">
      <div *ngFor="let item of carouselData" class="item">
        <sa-card-video
          [formatDate] = "formatDate"
          [isCategory] = 'isCategory'
          [isRuntime] = 'isRuntime'
          [isDateTimeImage] = 'isDateTimeImage'
          [isDateTime] = 'isDateTime'
          [isEntitlement] = 'isEntitlement'
          [isSubTitle] = 'isSubTitle'
          [typeCarousel]="typeCarousel"
          [getMetaData]="getMetaData"
          [componentData] = "item"
          [playlistPath] = "path">
        </sa-card-video>
      </div>
    </ng-container>
    <!--Mini Fixture Card-->
    <ng-container *ngSwitchCase = "'cardFixtureMin'">
      <div *ngFor = "let item of carouselData | slice:startNumberCards:endNumberCards;" class="item">
        <sa-card-fixture-min
          [formatDate] = "formatDate"
          [truncateText] = "truncateText"
          [isCompetition] = "isCompetition"
          [isDate] = "isDate"
          [fixtureData] = "item"
          [path]="path"
          [labelLiveButton]="labelLiveButton"
          [labelLiveTag]="labelLiveTag">
        </sa-card-fixture-min>
      </div>
    </ng-container>
    <!--Horizontal Fixture Card-->
    <ng-container *ngSwitchCase = "'cardFixtureHorizontal'">
      <div *ngFor="let item of carouselData" class="item item-card-horizontal">
          <sa-card-horizontal
            [buttonName] = "buttonName"
            [componentData] = "item"
            [isDescription] = "isDescription"
            [isEntitlement] = "isEntitlement"
            [isRuntime] = "isRuntime"
            [isTitle] = "isTitle"
            [truncateText] = "truncateText"
            [getMetaData]="getMetaData">
          </sa-card-horizontal>
      </div>
    </ng-container>

  </ng-container>
</div>
