<div *ngIf="title" class="title-duration">
  <h3 [textContent]="title" class="title"></h3>
</div>

<div class="container-duration">
  <div *ngFor="let item of data"
       (click)="onValueClick(item)"
       [ngClass]="{'active': item.selected}"
       class="content-duration">
    <div>
      <h3>{{item.label}}</h3>
      <p>{{item.value}}</p>
    </div>
  </div>
</div>
