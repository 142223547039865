import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-sso-register',
  templateUrl: './sso-register.component.html',
  styleUrls: ['./sso-register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SsoRegisterComponent implements OnInit {

  @Input() className = 'btn';
  @Input() nameRegisterBtn = ' ';
  @Input() registerLink: string;

  constructor() {
  }

  ngOnInit() {

  }
}
