import { Component, OnInit, Input } from '@angular/core';
import { static_data_footer } from './static-data-footer';

@Component({
  selector: 'sa-footer-columns',
  templateUrl: './footer-columns.component.html',
  styleUrls: ['./footer-columns.component.scss']
})
export class FooterColumnsComponent implements OnInit {

  @Input() footerItemList:any;

  constructor() { 
    this.footerItemList = this.footerItemList;
  }

  ngOnInit(): void {
  }

  public isExternal(link): boolean {
    const pat = /^https?:\/\//i;
    let response: boolean;
    if(link.includes('mailto')){
      response = true;
    }
    else{
      response = pat.test(link)
    }
    return response;
  }

}
