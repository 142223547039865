import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Boxsets} from '../../models/boxsets/boxsets.type';
import {PlayerUtils} from '../../utils/player-utils';

@Component({
  selector: 'sa-boxsets',
  templateUrl: './boxsets.component.html',
  styleUrls: ['./boxsets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoxsetsComponent implements OnInit {
  @Input() boxsetsData: Boxsets;
  @Input() path: string;
  @Input() isTitleBoxsets:boolean;
  @Input() isSubtitleBoxsets:boolean;

  defaultImage = '/assets/images/placeholder.jpg';

  constructor() {}

  ngOnInit() {

  }

  public getLink(blockIndex, itemIndex): string {
    const encodedPath = PlayerUtils.encodeUrlParamsForPlayer(`|${this.path}/${blockIndex}/items/${itemIndex}/feed`);
    return `/video/${encodedPath}`;
  }
}
