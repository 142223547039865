import { KWidgetEmbed } from '../models/video/kaltura.type';
import { VideoPlayerConfig, VideoPlayerConfigInternal } from '../models/video/video-player-config.type';
import { VideoPlayerProperties } from '../models/video/video-player-properties.type';

export class ConfigurationUtils {
  public static generateMissingVideoPlayerProperties(properties: VideoPlayerProperties): VideoPlayerProperties {
    return {
      scriptLoadErrorMessage: '',

      ...(properties ? properties : {})
    };
  }

  public static generateMissingVideoPlayerConfiguration(config: VideoPlayerConfig, playerId: string): VideoPlayerConfigInternal {
    if (!config) {
      return null;
    }

    const kalturaPlayerJsUrl = ConfigurationUtils.generateKalturaPlayerJsUrl(config);

    return {
      ...config,
      playerId,
      kalturaPlayerJsUrl,

      targetId: playerId
    };
  }

  public static generateKalturaPlayerJsUrl(config: VideoPlayerConfig): string {
    return `${config.kalturaBaseUrl}/p/${config.partnerId}/sp/${config.partnerId}00/embedIframeJs/uiconf_id/${config.uiConfId}/partner_id/`
      + config.partnerId;
  }

  /**
   * Generates the kaltura embed object.
   * @param config - Kaltura embed properties.
   * @param callback - The callback information involving the readyCallback and thisArg. The readyCallback will be executed once the
   *  player is initialised. The thisArg is used to determine the `this` property (for more information look into bind()) and is hooked
   *  up with the readyCallback.
   */
  public static generateKalturaMediaEmbedData<T>(config: VideoPlayerConfigInternal,
                                                 callback?: { readyCallback: (playerId: string) => void, thisArg: T }): KWidgetEmbed {
    return {
      targetId: config.playerId,
      wid: `_${config.partnerId}`,
      uiconf_id: config.uiConfId,
      entry_id: config.entryId,

      ...(callback ? { readyCallback: callback.readyCallback.bind(callback.thisArg) } : {}),

      ...(config.flashvars ? {flashvars: config.flashvars} : {})
    };
  }
}
