import { NgModule, ModuleWithProviders } from '@angular/core';
import { WordpressLibComponent } from './wordpress-lib.component';
import { CommonModule } from '@angular/common';
import { WordpressService } from './services/wordpress.service';

@NgModule({
  declarations: [WordpressLibComponent],
  imports: [
    CommonModule
  ],
  exports: [WordpressLibComponent],
  providers:[WordpressService]
})
export class WordpressLibModule {
  static forRoot(): ModuleWithProviders<WordpressLibModule> {
    return {
      ngModule: WordpressLibModule,
      providers: [
        WordpressService
      ]
    };
  }
}
