import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef } from '@angular/core';
import { FixturesState, WPNoContentData } from '../../models/fixture/amg.fixtures';
import { trigger, transition, style, animate, query, stagger } from '@angular/animations';


@Component({
  selector: 'sa-fixtures',
  templateUrl: './fixtures.component.html',
  animations:[
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('0.5s', style({ opacity: 1 }))
          ])
        ], { optional: true })
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixturesComponent implements OnInit {

  @Input() fixtureState: FixturesState;
  @Input() fixturesUrl:string;
  @Input() numberOfFixtures = '10';
  @Input() formatDate: string = 'dd.MM.yyyy';
  @Input() isCompetition: boolean;
  @Input() isTeamType: boolean;
  @Input() isDate: boolean;
  @Input() fixturesNoContent: WPNoContentData;
  @Input() fixturesError: string;
  @Input() labelLiveButton: string;
  @Input() labelLiveTag: string;

  isAnyFixturesLive = false;
  @Input() currentPageLoaded: boolean;
  @Input() isCalendarEvents: boolean;
  @Input() calendarButtonName: string;
  @Input() dataCalendar: any;

  @Output() onReset = new EventEmitter<string>();

  constructor(
    private cd: ChangeDetectorRef) {
    this.currentPageLoaded = false;
  }

  ngOnInit() {}

  resetFilter(){
    this.onReset.emit();
  }

  @Output() onEventCalendar = new EventEmitter<string>();

  onCalendarEvent(fixture){
    this.onEventCalendar.emit(fixture);
  }

}
