<div *ngIf="isDropdownTitle" class="title-dropdown">
  <h3 [textContent]="dropdownTitle" class="title"></h3>
</div>
<ng-select #ngSelectRef
           [(ngModel)]="selectedItemLabel"
           [clearable]="clearable"
           [items]="itemsData"
           [placeholder]="placeholder"
           [searchable]="searchable"
           groupBy="category">

  <ng-template let-index="index" ng-optgroup-tmp>
    <sa-accordion (triggerItemClick)="onItemClick($event)"
                  [data]="data">
    </sa-accordion>
  </ng-template>

</ng-select>

