export class DateTimeUtils {
    private static readonly secondsInADay = 86400;
    private static readonly secondsInAHour = 3600;
    private static readonly secondsInAMinute = 60;

    public static generateRuntimeBySeconds(seconds: number): string {
        const runtime = new Date(seconds * 1000)
            .toUTCString()
            .substring(17, 25);

        return runtime.startsWith('00:')
            ? runtime.substring(3)
            : runtime;
    }

    public static generateRuntimeObjectBySeconds(durationInSeconds: number)
        : { days: number; hours: number; minutes: number; seconds: number; } {

        const days = Math.floor(durationInSeconds / DateTimeUtils.secondsInADay);
        const daysTotalInSeconds = DateTimeUtils.secondsInADay * days;

        const hours = Math.floor((durationInSeconds - daysTotalInSeconds) / DateTimeUtils.secondsInAHour);
        const daysTotalInHours = DateTimeUtils.secondsInAHour * hours;

        const minutes = Math.floor((durationInSeconds - (daysTotalInSeconds + daysTotalInHours))
            / DateTimeUtils.secondsInAMinute);
        const daysTotalInMinutes = DateTimeUtils.secondsInAMinute * minutes;

        const seconds = Math.floor(durationInSeconds - (daysTotalInSeconds + daysTotalInHours + daysTotalInMinutes));

        return {days, hours, minutes, seconds};
    }

    public static padRuntimeValues(runtime: { days: number; hours: number; minutes: number; seconds: number; })
        : { days: string; hours: string; minutes: string; seconds: string; } {

        return {
            days: runtime.days.toString().padStart(2, '0'),
            hours: runtime.hours.toString().padStart(2, '0'),
            minutes: runtime.minutes.toString().padStart(2, '0'),
            seconds: runtime.seconds.toString().padStart(2, '0')
        };
    }
}
