

<app-header></app-header>

<div id="page-container">
    <router-outlet></router-outlet>
</div>
<!--SideBar Left-->
<sa-sidebar
    className = "left"
    [isSidebarImage] = "isSidebarImage"
    [itemList] = "listItems"
    [nameButton] = "buttonName">
</sa-sidebar>

<app-footer></app-footer>
