import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sa-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss']
})
export class SocialComponent implements OnInit {

  @Input() socialItemList = [
    {name:'Facebook', link:'https://www.facebook.com/'},
    {name:'Twitter', link:'https://twitter.com/'},
    {name:'YouTube', link:'https://www.youtube.com/'},
    {name:'Linkedin', link:'https://www.linkedin.com/'},
    {name:'Instagram', link:'https://www.instagram.com/'}
  ]

  constructor() { }

  ngOnInit() {
  }

}
