import {
  Component,
  OnInit,
  Renderer2,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  EventEmitter,
  Output,
  ViewChild
} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {ModalService} from '../../../services/modal.service';

@Component({
  selector: 'sa-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginModalComponent implements OnInit {
  private modalSubscription: Subscription;

  @Input() isTitleForm: boolean;
  @Input() titleForm: string;
  @Input() isUsernameLabel: boolean;
  @Input() isPasswordLabel: boolean;
  @Input() usernameLabel: string;
  @Input() passwordLabel: string;

  @Input() passwordPlaceHolder: string = 'Password';
  @Input() emailPlaceHolder: string = 'Email Address';
  @Input() forgotPwd: string = 'Forgot Password?';
  @Input() forgotPwdUrl: string;
  @Input() nameLoginBtn: string = 'Login';
  @Input() errorEmailRequired: string = "Email is required";
  @Input() invalidEmail: string = "Invalid Email";
  @Input() errorPwdRequired: string = "Password is required";
  @Input() errorPwdLength: string = "Password need to be min. 6";
  @Input() passwordLength: number = 6;

  @Input() isRegisterDescription:boolean;
  @Input() createAccount: string = "Don't you have an account?";

  @Input() nameRegisterBtn:string;
  @Input() registerLink:boolean;

  @Output() onSubmit = new EventEmitter<any>();
  public isOpened = false;
  public loginForm: FormGroup;

 /*@Input() formSubmissionMessageResponse = {
    success: '',
    error: ''
  };*/
  @Input() errorServer:string;

  @Input() packageMessage:string;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef) { }
    @ViewChild('loginContainer') loginContainerRef;

  ngOnInit() {
    this.modalSubscription = this.modalService.$openLoginModalObservable.subscribe((event) => {
      if (event === 'open-modal') {
        this.isOpened = true;
        this.cd.markForCheck();
      }
    });
  }

  closeModel() {
    this.isOpened = false;
    this.resetForm();
    this.cd.markForCheck();
  }

  resetForm() {
    this.loginContainerRef.loginForm.reset();
    this.errorServer = "";
    this.errorEmailRequired = '';
    this.errorPwdRequired = '';
    //this.formSubmissionMessageResponse.success = '';
    //this.formSubmissionMessageResponse.error = '';
  }

  onValueForm(term){
    if (this.loginContainerRef.loginForm.dirty && this.loginContainerRef.loginForm.valid)
        this.onSubmit.emit(term);
    else
        this.loginContainerRef.validateAllFormFields();
  }

  destroy(): void {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }

}
