export enum KalturaPlayerEvents {
  layoutBuildDone = 'layoutBuildDone',
  playerReady = 'playerReady',
  playing = 'playing',
  mediaLoaded = 'mediaLoaded',
  mediaError = 'mediaError',
  playerStateChange = 'playerStateChange',
  firstPlay = 'firstPlay',
  playerPlayed = 'playerPlayed',
  playerPaused = 'playerPaused',
  preSeek = 'preSeek',
  seek = 'seek',
  seeked = 'seeked',
  playerUpdatePlayhead = 'playerUpdatePlayhead',
  openFullScreen = 'openFullScreen',
  closeFullScreen = 'closeFullScreen',
  volumeChanged = 'volumeChanged',
  mute = 'mute',
  unmute = 'unmute',
  bufferChange = 'bufferChange',
  cuePointReached = 'cuePointReached',
  playerPlayEnd = 'playerPlayEnd',
  onChangeMedia = 'onChangeMedia',
  changeMedia = 'changeMedia',
  onChangeMediaDone = 'onChangeMediaDone',
  doPlay = 'doPlay',
  doPause = 'doPause',
  doSeek = 'doSeek',
  izsession = 'izsession',
  backToLive = 'backToLive',
  kdpReady = 'kdpReady',
  kdpEmpty = 'kdpEmpty',
  layoutReady = 'layoutReady',
  durationChange = 'durationChange',
  goLive = 'goLive',
  bufferProgress = 'bufferProgress',
  liveOnline = 'liveOnline',
  updateBufferPercent = 'updateBufferPercent',
  externalUpdatePlayHeadPercent = 'externalUpdatePlayHeadPercent',
  reattachTimeUpdate = 'reattachTimeUpdate',
  durationWithoutOmitSegments = 'durationWithoutOmitSegments',
  clippingSegments = 'clippingSegments',
  playerError = 'playerError'
}
