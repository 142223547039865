<footer>
    <sa-back2top [scrollDistance]="0"></sa-back2top>
  <!-- Sponsor -->
  <div class="container-sponsors">
      <div class="sponsors" *ngIf="footerPartnerPremium">
          <h3>{{partnerPremiumTitle}}</h3>
          <div class="content-sponsor sponsor-premium">
              <div class="box-img" *ngFor="let item of footerPartnerPremium">
                  <a target="_blank" [href]="item.link">
                  <img class="lazyload-image" [defaultImage]="defaultImage" [lazyLoad]="item.image" alt="sponsor"/>
                  </a>
              </div>
          </div>
      </div>
      <div class="sponsors" *ngIf="footerPartnerTop">
          <h3>{{partnerTopTitle}}</h3>
          <div class="content-sponsor sponsor-top">
              <div class="box-img" *ngFor="let item of footerPartnerTop">
                  <a target="_blank" [href]="item.link">
                  <img class="lazyload-image" [defaultImage]="defaultImage" [lazyLoad]="item.image" alt="sponsor"/>
                  </a>
              </div>
          </div>
      </div>
      <div class="sponsors" *ngIf="footerPartner">
          <h3>{{partnerTitle}}</h3>
          <div class="content-sponsor sponsor-partner">
              <div class="box-img" *ngFor="let item of footerPartner">
                  <a target="_blank" [href]="item.link">
                  <img class="lazyload-image" [defaultImage]="defaultImage" [lazyLoad]="item.image" alt="sponsor"/>
                  </a>
              </div>
          </div>
      </div>
  </div>

  <!-- Social Newsletters -->
  <div class="social-newsletter">
      <div class="newsletter-content">
          <h3 [textContent]="newsTitle"></h3>
          <p [textContent]="newsSubtitle"></p>
          <a class="btn" target="_blank" [textContent]="newsLinkName" [href]="newsLink"></a>
      </div>
      <div class="social-content">
          <h3 [textContent]="socialTitle"></h3>
          <p [textContent]="socialSubtitle"></p>
          <div class="social-list">
              <div class="social-item" *ngFor="let item of socialLink">
                  <a [href]="item.link">
                      <img [src]="item.image" alt="social"/>
                  </a>
              </div>
          </div>
      </div>
  </div>

  <!--Footer Column-->
  <sa-footer-columns
      [footerItemList] = "footerItemList">
  </sa-footer-columns>

  <div class="copyright">
      <p [textContent]="footerCopyright"></p>
      <div *ngFor="let linkItem of footerCopyrightLink">
         <span>|</span><a [href]="linkItem.link" target="_blank">{{linkItem.label}}</a>
      </div>
  </div>
</footer>
