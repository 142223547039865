import {ChangeDetectorRef, Component, Inject, Input, LOCALE_ID, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AmgFixtureEvent} from '../../../models/fixture/amg.fixture';
import {FixtureService} from '../../../services/fixture.service';
import {FixtureBaseComponent} from '../../../utils/fixture/fixture-base.component';
import {PlayerUtils} from '../../../utils/player-utils';

@Component({
  selector: 'sa-card-fixture-min',
  templateUrl: './card-fixture-min.component.html'
})
export class CardFixtureMinComponent extends FixtureBaseComponent implements OnInit {
  public static readonly mediaType = 'video';
  defaultImage = '/assets/images/placeholder.jpg';
  date: any;
  link: string;

  @Input() fixtureData: AmgFixtureEvent;
  @Input() formatDate: string = 'dd.MM.yyyy';
  @Input() truncateText: number = 10;
  @Input() isCompetition: boolean;
  @Input() isDate: boolean;
  @Input() labelLiveButton: string;
  @Input() labelLiveTag: string;
  @Input() path: string;
  @Input() basePath: string = '/live';

  constructor(protected http: HttpClient,
              protected fixtureService: FixtureService,
              protected cd: ChangeDetectorRef,
              @Inject(LOCALE_ID) public locale: string) {
    super(http, fixtureService, cd);
  }

  ngOnInit(): void {
    super.init();
    this.date = this.getFormattedDate(this.formatDate, this.locale);


      const path = PlayerUtils.encodeUrlParamsForPlayer(`${this.getEntryId(CardFixtureMinComponent.mediaType)}`);
      this.link = `${this.basePath}/${path}`;

  }

  public getTime(): string {

    return super.getFormattedDate('HH:mm', this.locale) + ' Uhr';
  }
}

