<ng-container *ngIf="!isLoggedIn">
  <app-sso-login [nameLoginBtn]="' '" [className]="'btn-menu'"></app-sso-login>
</ng-container>

<ng-container *ngIf="isLoggedIn">
  <div class="login-user-info">
    <span>{{'my-account.welcome-text'  | commonLabel | async}}</span><span class="user-name">{{firstName}}</span>
  </div>
  <div class="selection-menu" [ngClass]="{ 'show': isMyAccountPanelOpen }">
    <!--
    <div class="row">
      <div>
        <a [href]="profileUrl" [textContent]="'my-account.your-profile' | commonLabel | async"></a>
      </div>
    </div>
    -->

    <div class="row">
      <!--Logout Button-->
      <div class="logout-section" (click)="doLogout($event)">
        <a (click)="doLogout($event)" href="#" [textContent]="'logout' | commonLabel | async"></a>
      </div>
    </div>
  </div>
</ng-container>
