import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wp-wordpress-lib',
  template: `
    <p>
      wordpress-lib works!
    </p>
  `,
  styles: []
})
export class WordpressLibComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
