export const environment = {
  production: false,

  google: {
    analyticsId: ''
  },

  fixtures: {
     url: 'https://staging.api.streamplay.streamamg.com/fixtures/football/p/3001396/',
    // url: 'https://api.streamplay.streamamg.com/fixtures/football/p/3001396/',
    contentPageSize: 10
  },

  wordpress: {
    api: 'https://wp-devaustrianfa.streamamg.com/wp-json/wpa/v1',
    pages: {
      home: '/section?slug=home',
      fixtures: '/section?slug=fixtures',
      browse: '/section?slug=browse',
      faqs: '/section?slug=faqs',
      playlists: '/section?slug=playlists',
      video: '/section?slug=video',
    },
    commonData: '/common_field?slug=common-fields'
  },

  sso: {
    url: 'https://fvtest.fussballoesterreich.at',
    middleware: 'https://lpkjrk6zsb.execute-api.eu-west-1.amazonaws.com/dev'
  }
};
