import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CommonLabelPipe} from './pipe/common-label.pipe';
import {SafeHtmlPipe} from './pipe/safe-html.pipe';

@NgModule({
  declarations: [
    CommonLabelPipe,
    SafeHtmlPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CommonLabelPipe,
    SafeHtmlPipe
  ]
})
export class SharedModule { }
