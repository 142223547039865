export class UrlUtils {
    public static generateUrlParameters(params: { [key: string]: string }): string {
        //let urlParam:string =
        /*Object.keys(params).length
            ? Object.keys(params)
                .reduce((stringDataArray, key) => {
                    const value = params[key];

                    return [
                        ...stringDataArray,
                        `${key}=${value}`
                    ];
                }, [])
                .join('&')
            : null;*/

        //return urlParam;
         return Object.keys(params).length
             ? Object.keys(params)
                 .reduce((stringDataArray, key) => {
                     const value = params[key];

                     return [
                         ...stringDataArray,
                         `${key}=${value}`
                     ];
                 }, [])
                 .join('&')
             : null;
    }

    public static extractUrlParamsFromExistingUrl(url: string): { [key: string]: string } {
        const urlParamIndex = url.indexOf('?');

        if (urlParamIndex !== -1) {
            return url.substring(urlParamIndex + 1)
                .split('&')
                .reduce((previousValue, currentValue) => {
                    const keyValue = currentValue.split('=');
                    return {
                        ...previousValue,
                        [keyValue[0]]: keyValue[1]
                    };
                }, {});
        }

        return {};
    }

    public static removeQueryParameters(url: string): string {
        const index = url.indexOf('?');
        return index === -1
            ? url
            : url.substr(0, index);
    }
}
