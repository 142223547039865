import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { LoginBtnComponent } from './login-btn/login-btn.component';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { SignupBtnComponent } from './signup-btn/signup-btn.component';
import { RegistrationComponent } from './registration/registration.component';

@NgModule({
  declarations: [ 
    LoginComponent, 
    LoginBtnComponent, 
    LoginModalComponent,
    SignupBtnComponent,
    RegistrationComponent
    ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule 
  ],
  exports: [
    LoginComponent, 
    LoginBtnComponent,
    LoginModalComponent,
    SignupBtnComponent,
    RegistrationComponent
  ]
})
export class UserAuthLibModule {
 
}
