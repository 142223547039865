import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AccordionComponent } from './accordion.component';

@NgModule({
  declarations: [ 
    AccordionComponent
    ],
  imports: [
    RouterModule,
    CommonModule
  ],
  exports: [
    AccordionComponent
  ]
})
export class AccordionModule {
 
}
