import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'sa-card-game',
  templateUrl: './card-game.component.html',
  styleUrls: ['./card-game.component.scss']
})
export class CardGameComponent implements OnInit {
  defaultImage = '/assets/images/placeholder.jpg';
  defaultImageLogo = '/assets/images/placeholder.jpg';

  @Input() componentDataGame:any;
  @Input() className = '';
  @Input() thumbnail: string;
  @Input() link: string;
  @Input() linkImg: string;
  @Input() linkLogoImg: string;
  @Input() linkLogoImg_bw: string;
  @Input() index: string;

  constructor(private router: Router) { }

  ngOnInit(): void {
    if (this.componentDataGame) {

      if(this.componentDataGame.image.length > 0){
         this.linkImg = this.componentDataGame.image;
         this.linkLogoImg_bw = this.componentDataGame.logo_bw;
         this.linkLogoImg = this.componentDataGame.logo;
      }
      else{
        this.linkImg = this.defaultImage;
        this.linkLogoImg_bw = this.defaultImageLogo;
        this.linkLogoImg = this.defaultImageLogo;
      }
    }
  }

  routing(link){
    this.router.navigate([link]);
 }

}
