import { 
  Component, 
  HostListener, 
  OnInit, 
  Inject, 
  PLATFORM_ID, 
  HostBinding, 
  Input,
  ChangeDetectionStrategy 
} from '@angular/core';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { ScrollService } from '../../services/scroll.service';

@Component({
  selector: 'sa-back2top',
  templateUrl: './back2top.component.html',
  styleUrls: ['./back2top.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Back2topComponent implements OnInit {

  @HostBinding('class.isVisible') isVisible = false
  @Input() scrollDistance = 200;
  isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) platformId, public _scrollService: ScrollService) {
    this.isBrowser = isPlatformBrowser(platformId);
   }

  ngOnInit() {
  }

  moveTop(){
    this._scrollService.scrollToTop();
  }

  @HostListener('window:scroll', [])
    onWindowScroll() {
        if (this.isBrowser) {
            this.isVisible = this.getCurrentScrollTop() > this.scrollDistance ? true : false;
        }
    }

  getCurrentScrollTop() {
    if (typeof window.scrollY !== 'undefined' && window.scrollY >= 0) {
        return window.scrollY;
    }

    if (typeof window.pageYOffset !== 'undefined' && window.pageYOffset >= 0) {
        return window.pageYOffset;
    }

    if (typeof document.body.scrollTop !== 'undefined' && document.body.scrollTop >= 0) {
        return document.body.scrollTop;
    }

    if (typeof document.documentElement.scrollTop !== 'undefined' && document.documentElement.scrollTop >= 0) {
        return document.documentElement.scrollTop;
    }

    return 0;
}

}
