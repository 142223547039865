import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Renderer2,
  Inject, OnDestroy
} from '@angular/core';
import {Subscription} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {WordpressService} from 'wordpress-lib';
import {environment} from '../../../../environments/environment';
import {ModalService, UserAuthSsoService, UserState} from 'components-lib';
import {MenuItem, SubLink, SubMenuItem} from '../../models/wordpress/common-labels.type';
import {TeamLandingPageService} from '../../services/team-landing-page.service';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {
  public commonData: any;
  public menuData: any;
  public commonLabels: { [key: string]: string };
  public toggleMenu = false;
  liveItem: {label: string; link: string; isLive: boolean};
  public isLoggedIn = false;
  public firstName: string;
  // Click for submenu mobile
  selectedItem: string;
  private commonDataSubscription: Subscription;
  private routerSubscription: Subscription;
  private commonDataUrl = '';

  public isMyAccountPanelOpen = false;

  setIsMyAccountPanelOpen(isMyAccountPanelOpen: boolean): void {
    this.isMyAccountPanelOpen = isMyAccountPanelOpen;
  }

  constructor(
    private wordpressService: WordpressService,
    private userAuthSsoService: UserAuthSsoService,
    private modalService: ModalService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private teamLandingPageService: TeamLandingPageService,
    private router: Router,
    private cd: ChangeDetectorRef) {

    this.commonDataUrl = `${environment.wordpress.api}${environment.wordpress.commonData}`;
  }

  ngOnInit(): void {
    this.getMenuData();

    this.routerSubscription = this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => {
          this.cd.markForCheck();
        })

    this.userAuthSsoService.userStateObservable.subscribe(userState => {
      if (userState) {
        this.isLoggedIn = userState.state !== UserState.notLoggedIn;
        if (this.isLoggedIn)
          this.firstName = userState.details.CurrentCustomerSession.CustomerFirstName;
        this.cd.markForCheck();
      }
    });
  }

  getMenuData() {
    this.commonDataSubscription = this.wordpressService.getWordpressData<any>(this.commonDataUrl)
      .subscribe(data => {
        if (data as any) {
          data.menu[0] = {
            ...data.menu[0],
            isLive: false
          };

          this.commonData = data;
          this.liveItem = data.menu[0];
          this.menuData = data.menu;
          this.commonLabels = data.labels;
          this.cd.markForCheck();
        }
      });
  }

  isExternal(link): boolean {
    const pat = /^https?:\/\//i;
    return pat.test(link);
  }

  // Toggle for mobile menu open/close
  menuToggle(val) {
    this.toggleMenu = val;
    if (val)
      this.renderer.addClass(this.document.body.parentElement, 'menu-opened');
    else {
      this.selectedItem = '';
      this.renderer.removeClass(this.document.body.parentElement, 'menu-opened');
    }
    this.cd.markForCheck();
  }

  openSubmenu(event: Event, item) {
    if (item === this.selectedItem)
      this.selectedItem = '';
    else
      this.selectedItem = item;
  }

  openSearchModal(event): void {
    // event.preventDefault();
    // this.modalService.openModal();
  }

  generateRouterLink(menuItem: MenuItem, subMenuItem: SubMenuItem, subLink: SubLink): string {
    return this.teamLandingPageService.generateTeamLandingRouterPath(menuItem, subMenuItem, subLink);
  }

  ngOnDestroy(): void {
    this.commonDataSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }

  isSubMenuSelected(routerLink: string): boolean {
    return this.router.url.startsWith(routerLink + '/');
  }
}
