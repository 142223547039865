<a [routerLink]="link" [className]='className'>
    <div class="card-video-container">
      <div class="image-container">
        <div class="image lazyload-image" [lazyLoad]="linkImg" [defaultImage]="defaultImage"></div>
        <div class="card-info">
          <div *ngIf="isRuntime" class="runtime " [textContent]="runtime"></div>
          <div *ngIf="isEntitlement" class="entitlement" [attr.data-entitlement]="entitlementKey"></div>
          <time *ngIf="isDateTimeImage" class="" [dateTime]="date" [textContent]="date"></time>
          <div *ngIf="isSubTitle && subTitle" class="subtitle" [textContent]="subTitle"></div>
        </div>
      </div>

      <div class="card-body">
        <h5 *ngIf="isCategory" class="categories" [textContent]="categories"></h5>
        <h2 class="title" [title]="title" [textContent]="title"></h2>
      </div>
    </div>
</a>
