

<div class="fixture-container"
    *ngIf="fixtureState && fixtureState.data.success.data.length != 0; else noFixtures"
    [@listAnimation]="fixtureState.data.success.data.length">
    <sa-card-fixture *ngFor="let fixture of fixtureState.data.success.data; let i = index"
        [fixtureData] = "fixture"
        [formatDate] = "formatDate"
        [isCompetition] = "isCompetition"
        [isTeamType] = "isTeamType"
        [labelLiveTag]="labelLiveTag"
        [labelLiveButton] = "labelLiveButton"
        [isDate] = "isDate"
        (onEventCalendar) = "onCalendarEvent(fixture)"
        [indexCalendar] = "i"
        [isCalendarEvents] = "isCalendarEvents"
        [dataCalendar] = "dataCalendar"
        [calendarButtonName] = "calendarButtonName">
    </sa-card-fixture>
</div>

<ng-template #noFixtures>
    <div
      *ngIf="fixtureState.data && fixtureState.data.success.data.length == 0 && fixturesNoContent; else error"
      class="result empty-result">
       <!-- <div class="image"></div>-->
        <p [textContent]="fixturesNoContent.title"></p>
        <p class="subtitle" [textContent]="fixturesNoContent.subtitle"></p>
        <div class="button">
            <button type="button" class="btn"
            [textContent]="fixturesNoContent.button_name"
            (click)="resetFilter()"></button>
        </div>
    </div>
</ng-template>
<ng-template #error>
    <div *ngIf="fixtureState.data.error" class="result empty-result"
        [textContent]="fixturesError"></div>
</ng-template>
