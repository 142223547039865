import {Observable, ReplaySubject, Subject} from 'rxjs';
import {
  GenericPaymentResponse,
  KSessionRequest,
  StreamPayments,
  SubscriptionPackageListResponse,
  UserState,
  UserStateObservable
} from '../../models/userAuth/user-auth.type';

declare var streamPayments: StreamPayments;


export class UserAuth {
  protected streamPayments: StreamPayments;
  // Observables
  private userLoginState: ReplaySubject<UserStateObservable> = new ReplaySubject<UserStateObservable>(1);
  public userStateObservable: Observable<UserStateObservable> = this.userLoginState.asObservable();
  protected apisessionid: string;
  private subjectLoginModal = new Subject<string>();
  public $openLoginModalObservable: Observable<string> = this.subjectLoginModal.asObservable();


  constructor() {
    this.userLoginState.next({state: UserState.notLoggedIn});
    try {
      this.streamPayments = streamPayments;
    } catch (err) {

      throw new Error('UserAuth error: ' + err);
    }
  }

  public openLoginModal(): void {

    this.subjectLoginModal.next('open-modal');
  }

  protected streamPaymentsSetSessionState(options?, callback?: (response: GenericPaymentResponse) => void): void {


    try {

      this.streamPayments.getSessionState(options, (response: GenericPaymentResponse) => {

        let userState: UserState = UserState.notLoggedIn;
        if (response.CurrentCustomerSession !== null) {
          this.apisessionid = response.CurrentCustomerSession.Id;

          if (response.CurrentCustomerSession.CustomerEntitlements !== null) {
            userState = UserState.subscribed;
          } else {
            userState = UserState.loggedIn;
          }
        } else {
          this.apisessionid = null;
        }

        this.userLoginState.next({
          state: userState,
          details: response
        });

        if (callback) {
          callback(response);
        }
      });

    } catch (err) {

      this.userLoginState.next({
        state: UserState.notLoggedIn
      });

      if (callback) {
        callback(null);
      }

      throw new Error('UserAuth error: ' + err);
    }
  }

  public streamPaymentsGetKSession(data: KSessionRequest, callback: (res: GenericPaymentResponse) => void): void {

    try {
      this.streamPaymentsSetSessionState(null, (response) => {
        data.apisessionid = this.apisessionid;
        this.streamPayments.getKSession(data, callback);
      });
    } catch (e) {

      callback(null);
    }
  }

  public streamPaymentsGetPackages(options, callback: (res: SubscriptionPackageListResponse) => void): void {
    options = options || {};
    options.apisessionid = this.apisessionid;
    try {
      if (typeof streamPayments !== 'undefined') {
        this.streamPayments.getSubscriptionPackageList(options, callback);
      }
    } catch (err) {

      throw new Error('UserAuth error: ' + err);
    }
  }

  public streamPaymentsLogOut(options?, callback?: (res: GenericPaymentResponse) => void): void {
    options = options || {};
    options.apisessionid = this.apisessionid;
    try {
      this.streamPayments.doLogout(options, (response) => {
        this.apisessionid = null;
        this.userLoginState.next({
          state: UserState.notLoggedIn,
          details: response
        });

        if (callback) {
          callback(response);
        }
      });
    } catch (err) {

      this.userLoginState.next({
        state: UserState.notLoggedIn
      });

      if (callback) {
        callback(null);
      }

      throw new Error('UserAuth error: ' + err);
    }
  }
}
