import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sa-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {

  @Input() fieldValue:string;
  @Input() placeholder:string;
  @Input() searchLabel:string;
  @Input() iconSearchColor:string;

  @Output() searchTrigger: EventEmitter<string>;
  private previousEmittedValue: string;

  public isFocused: boolean;

  constructor() {
    this.previousEmittedValue = null;
    this.isFocused = false;
    this.fieldValue = '';
    this.placeholder = '';
    this.iconSearchColor = '#192951';
    this.searchTrigger = new EventEmitter<string>()
  }

  handleSearch(event: KeyboardEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.triggerSearch(event);
  }

  /**
   * IE 11 fix as it doesn't support type='search'
   */
  handleKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.triggerSearch(event);
    }
  }

  onFocus(isFocused: boolean): void {
    this.isFocused = isFocused;
  }

  private triggerSearch(event: KeyboardEvent): void {
    const searchValue = (event.target as HTMLInputElement).value;

    if (searchValue !== this.previousEmittedValue) {
      this.searchTrigger.emit(searchValue);
    }

    this.previousEmittedValue = searchValue;
  }
}
