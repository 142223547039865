import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {UserAuthSsoService} from 'components-lib';
import {Router} from '@angular/router';
import {configs} from '../../../configs';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-sso-button',
  templateUrl: './sso-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SsoButtonComponent {
  declare window;

  @Output() elementClick = new EventEmitter<boolean>();
  @Input() firstName = '';
  @HostBinding('class.login-panel') loginPanel = true;
  @HostBinding('class.active') active = false;

  // public profileUrl = environment.sso.url + configs.sso.paths.profile;

  private _isMyAccountPanelOpen: boolean;

  get isMyAccountPanelOpen(): boolean {
    return this._isMyAccountPanelOpen;
  }

  @Input() set isMyAccountPanelOpen(value: boolean) {
    this._isMyAccountPanelOpen = value;
    this.setIsLoginPanelActive();
  }

  private _isLoggedIn: boolean;

  get isLoggedIn(): boolean {
    return this._isLoggedIn;
  }

  @Input() set isLoggedIn(value: boolean) {
    this._isLoggedIn = value;
    this.setIsLoginPanelActive();
  }

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    event.stopImmediatePropagation();
    this.elementClick.emit(!this._isMyAccountPanelOpen);
  }

  constructor(private userAuthSsoService: UserAuthSsoService,
              private router: Router) {
  }

  private setIsLoginPanelActive(): void {
    this.active = this._isLoggedIn && this._isMyAccountPanelOpen;
  }

  public doLogout(event) {
    event.preventDefault();
    this.userAuthSsoService.streamPaymentsLogOut(null, () => {
      window.location.href = `${environment.sso.url + configs.sso.paths.logout}?redirectUrl=${window.location.href}`;
    });
  }
}
