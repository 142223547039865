import { DeviceUtils } from './device-utils';

export class FlickityUtils {
    private static readonly containerId = 'page-container';
    private static readonly carouselClickElement = 'a';

    public static indexInParentDom(node: Element): number {
        const children = node.parentNode.childNodes;
        let num = 0;

        children.forEach(child => {
            if (child === node) {
                return num;
            } else if (child.nodeType === 1) {
                num++;
            }
        });

        return -1;
    }

    public static flickityBugFix(flickity: any,
                                 carouselElement: HTMLElement,
                                 navigator: Navigator,
                                 containerId?: string,
                                 carouselClickElement?: string): void {

        const isIOSDevice = DeviceUtils.isIOSDevice(navigator);
        containerId = containerId || FlickityUtils.containerId;
        carouselClickElement = carouselClickElement || FlickityUtils.carouselClickElement;

        flickity.on('dragStart', () => {
            // fix IOS bug
            if (isIOSDevice) {
                document.getElementById(containerId).ontouchmove = e => e.preventDefault();
            }

            // fix click slide on drag
            Array.from(carouselElement.querySelectorAll(carouselClickElement)).forEach((el: HTMLElement) => {
                el.style.pointerEvents = 'none';
            });
        }).on('dragEnd', () => {
            if (isIOSDevice) {
                document.getElementById(containerId).ontouchmove = () => true;
            }

            Array.from(carouselElement.querySelectorAll(carouselClickElement)).forEach((el: HTMLElement) => {
                el.style.pointerEvents = 'all';
            });
        });
    }
}
