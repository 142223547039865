import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CarouselComponent } from './carousel.component';
import { CardsModule } from '../cards/cards.module';
import { LoaderModule } from '../../components/loader/loader.module';

@NgModule({
  declarations: [ 
      CarouselComponent
    ],
  imports: [
    RouterModule,
    CommonModule,
    LazyLoadImageModule,
    CardsModule,
    LoaderModule
  ],
  exports: [
    CarouselComponent
  ]
})
export class CarouselModule {
 
}
