import { Component, OnInit, Input } from '@angular/core';
import {PlayerUtils} from '../../../utils/player-utils';

@Component({
  selector: 'sa-card-player',
  templateUrl: './card-player.component.html'
})
export class CardPlayerComponent implements OnInit {
  defaultImage = '/assets/images/placeholder.jpg';
  @Input() componentDataPlayer:any;
  @Input() className = 'link';
  @Input() isName:boolean;
  @Input() isSurname:boolean;

  @Input() name: string;
  @Input() surname: string;
  @Input() thumbnail: string;

  public link: string;
  @Input() linkImg: string;

  @Input() path: string;
  @Input() basePath: string;

  ngOnInit() {
    if (this.componentDataPlayer) {
      const data = PlayerUtils.encodeUrlParamsForPlayer(`${this.path}`);
      this.link = `${this.basePath}/${data}`;
      this.name = this.componentDataPlayer.name;

      if(this.componentDataPlayer.image.length > 0)
        this.linkImg = this.componentDataPlayer.image;
      else
        this.linkImg = this.defaultImage;
    }
  }
}
