
<div class="hero-registration-content">
    <form [formGroup]="registrationForm" (submit)="formSubmit($event)">
        <div *ngIf="isTitleForm" class="title"><h1 [textContent]="titleForm"></h1></div>
        <div *ngIf="isSubtitleForm" class="subtitle"><h3 [textContent]="subtitleForm"></h3></div>
        <fieldset class="box-name">
            <label *ngIf="isNameLabel" [textContent]="nameLabel"></label>
            <input formControlName="name" type="text" [placeholder]="namePlaceHolder"/>
            <div class="error-txt"
                *ngIf="registrationForm.controls['name'].touched && !registrationForm.controls['name'].valid">
            <span *ngIf="registrationForm.controls['name'].hasError('required')"
                    [textContent]="errorFieldRequired"></span>
            </div>
        </fieldset>
        <fieldset class="box-surname">
            <label *ngIf="isSurnameLabel" [textContent]="surnameLabel"></label>
            <input formControlName="surname" type="text" [placeholder]="surnamePlaceHolder"/>
            <div class="error-txt"
                *ngIf="registrationForm.controls['surname'].touched && !registrationForm.controls['surname'].valid">
            <span *ngIf="registrationForm.controls['surname'].hasError('required')"
                    [textContent]="errorFieldRequired"></span>
            </div>
        </fieldset>
        <fieldset class="box-date">
            <label *ngIf="isDateOfBirthLabel" [textContent]="dateOfBirthLabel"></label>
            <input formControlName="dateOfBirth" type="text" [placeholder]="dateOfBirthPlaceHolder"/>
            <div class="error-txt"
                *ngIf="registrationForm.controls['dateOfBirth'].touched && !registrationForm.controls['dateOfBirth'].valid">
            <span *ngIf="registrationForm.controls['dateOfBirth'].hasError('required')"
                    [textContent]="errorFieldRequired"></span>
            </div>
        </fieldset>
        <fieldset class="box-email">
            <label *ngIf="isEmailLabel" [textContent]="emailLabel"></label>
            <input formControlName="emailaddress" type="email" [placeholder]="emailPlaceHolder"/>
            <div class="error-txt"
                *ngIf="registrationForm.controls['emailaddress'].touched && !registrationForm.controls['emailaddress'].valid">
            <span *ngIf="registrationForm.controls['emailaddress'].hasError('required')"
                    [textContent]="errorEmailRequired"></span>
            <span *ngIf="registrationForm.controls['emailaddress'].hasError('pattern')"
                    [textContent]="invalidEmail"></span>
            </div>
        </fieldset>
        <fieldset class="box-password">
            <label *ngIf="isPasswordLabel" [textContent]="passwordLabel"></label>
            <input formControlName="password" type="password" [placeholder]="passwordPlaceHolder"/>
            <div class="error-txt"
                *ngIf="registrationForm.controls['password'].touched && !registrationForm.controls['password'].valid">
            <span *ngIf="registrationForm.controls['password'].hasError('required')"
                    [textContent]="errorPwdRequired"></span>
            <span *ngIf="registrationForm.controls['password'].hasError('minlength')"
                    [textContent]="errorPwdLength"></span>
            </div>
        </fieldset>
        <div class="box-registration-btn">
            <p class="error-msg">{{errorServer}}</p>
            <button type="submit" class="btn" [textContent]="nameRegisterBtn"></button>
        </div>
    </form>
</div>