import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SsoLoginComponent } from './sso-login/sso-login.component';
import {SsoRegisterComponent} from './sso-register/sso-register.component';



@NgModule({
  declarations: [
    SsoLoginComponent,
    SsoRegisterComponent],
  imports: [
    CommonModule
  ],
  exports: [
    SsoLoginComponent,
    SsoRegisterComponent
  ]
})
export class SsoLoginModule { }
