import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { DataCalendar } from '../../models/calendarEvent/calendar.type';


@Component({
  selector: 'sa-calendar-events',
  templateUrl: './calendar-events.component.html'
})

export class CalendarEventsComponent implements OnInit {

  @Input() calendarButtonName:string = "Add to Calendar";
  @Input() isCalendarEvents: boolean;
  @Input () dataCalendar: DataCalendar;
  @Output() onEventCalendar = new EventEmitter<string>();
  btnActive: boolean;
  @Input() indexCalendar: number = 0;
  selectedIndex: number;

  constructor(protected cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.selectedIndex = null;
    this.dataCalendar = null;
    this.btnActive = false;
  }

  /*data: dataCalendar = {
    title:"title",
    description:"description",
    location:"location"
  }*/

  /*private createdTime = new Date().toISOString();
  private startDate = new Date().toISOString();
  private endDate = new Date().toISOString();
  private id = 'id123';
  private summary = 'summary123';
  private location = 'location123';
  private description = 'description123';*/

  public getGoogleCalendarUrl() {
    let googleCalendarUrl = 'https://www.google.com/calendar/render?action=TEMPLATE';
    googleCalendarUrl += '&text=' + encodeURI(this.dataCalendar.title);
    googleCalendarUrl += '&dates=' + encodeURI(this.dataCalendar.startDate) + '/' + encodeURI(this.dataCalendar.endDate);
    googleCalendarUrl += '&details=' + encodeURI(this.dataCalendar.description);
    googleCalendarUrl += '&location=' + encodeURI(this.dataCalendar.location);

    return googleCalendarUrl;
  }

 /* private calendarData = [
    'data:text/calendar;charset=utf8,',
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'BEGIN:VEVENT',
    'DESCRIPTION:' + this.description,
    'DTSTART:' + this.startDate,
    'DTEND:' + this.endDate,
    'LOCATION:' + this.location,
    'SUMMARY:' + this.summary,
    'TRANSP:TRANSPARENT',
    'END:VEVENT',
    'END:VCALENDAR',
    'UID:' + this.id,
    'DTSTAMP:' + this.createdTime,
    'PRODID:website-1.0'
  ].join('\n');*/


  addToCalendar(index): void {
    this.onEventCalendar.emit();

    setTimeout(() => {
      if(this.dataCalendar && this.dataCalendar != null){
        this.selectedIndex = index;
        window.open(this.getGoogleCalendarUrl());
        this.cd.markForCheck();
      }
   });
  }

}
