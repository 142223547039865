import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs';
import {LabelService} from '../../core/services/label.service';

@Pipe({
  name: 'commonLabel'
})
export class CommonLabelPipe implements PipeTransform {
  constructor(private labelService: LabelService) {
  }

  transform(key: string): Observable<string> {
    return this.labelService.getLabel(key);
  }
}
