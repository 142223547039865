import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { WordpressService } from 'wordpress-lib';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {

  //NewsLetters
  newsTitle: string;
  newsSubtitle: string;
  newsLinkName: string;
  newsLink: string;

  //Social
  socialTitle: string;
  socialSubtitle: string;
  socialLink: any;

  private commonDataSubscription: Subscription;
  footerItemList: any;
  footerPartnerPremium: any;
  footerPartnerTop: any;
  footerPartner: any;
  partnerPremiumTitle: string;
  partnerTopTitle: string;
  partnerTitle: string;
  private commonDataUrl:string = '';
  defaultImage = '/assets/images/placeholder.jpg';
  footerCopyright:string;
  footerCopyrightLink: any;

  constructor(
    private wordpressService: WordpressService,
    private cd: ChangeDetectorRef
  ) {
    this.commonDataUrl = `${environment.wordpress.api}${environment.wordpress.commonData}`;
  }

  ngOnInit(): void {
    this.getCommonData();
  }

  getCommonData(){
    this.commonDataSubscription = this.wordpressService.getWordpressData<any>(this.commonDataUrl)
    .subscribe(data => {
      if (data as any) {
        this.footerItemList = data.footer_link;
        this.socialTitle = data.footer_social.title;
        this.socialSubtitle = data.footer_social.subtitle;
        this.socialLink = data.footer_social.social_link;
        this.newsTitle = data.footer_newsletters.title;
        this.newsSubtitle = data.footer_newsletters.subtitle;
        this.newsLinkName = data.footer_newsletters.button.name;
        this.newsLink = data.footer_newsletters.button.link;

        this.partnerPremiumTitle = data.footer_partner_premium.title;
        this.partnerTopTitle = data.footer_partner_top.title;
        this.partnerTitle = data.footer_partner.title;
        this.footerPartnerPremium = data.footer_partner_premium.list;
        this.footerPartnerTop = data.footer_partner_top.list;
        this.footerPartner = data.footer_partner.list;
        this.footerCopyright = data.footer_copyright;
        this.footerCopyrightLink = data.footer_copyright_link.content_link;
        this.cd.markForCheck();
      }
    });
  }

}
