<div class="modal-login" [class.opened]="isOpened">
    <div class="modal-content">
        <!-- <amg-loader *ngIf="isLoading"></amg-loader> -->
        <div class="close-btn" (click)="closeModel()"></div>
        <div class="box-data">
            <div class="header">
              <div class="logo">
                <div></div>
              </div>
            </div>
            <div class="content">
              <sa-login #loginContainer
                [isTitleForm] = "isTitleForm"
                [titleForm] = "titleForm"
                [isUsernameLabel] = "isUsernameLabel"
                [usernameLabel] = "usernameLabel"
                [isPasswordLabel] = "isPasswordLabel"
                [passwordLabel] = "passwordLabel"
                [emailPlaceHolder] = "emailPlaceHolder"
                [invalidEmail] = "invalidEmail"
                [passwordPlaceHolder] = "passwordPlaceHolder"
                [passwordLength] = "passwordLength"
                [forgotPwd] = "forgotPwd"
                [forgotPwdUrl] = "forgotPwdUrl"
                [errorPwdLength] = "errorPwdLength"
                [errorEmailRequired] = "errorEmailRequired"
                [errorPwdRequired] = "errorPwdRequired"
                (submit) = "onValueForm($event)"
                [nameLoginBtn] = 'nameLoginBtn'
                [errorServer] = 'errorServer'>
              </sa-login>
            </div>
            <div class="register-container">
              <div *ngIf="isRegisterDescription" class="txt-new-account" [textContent]="createAccount"></div>
                <sa-signup-btn 
                [nameRegisterBtn] = "nameRegisterBtn"
                [registerLink] = "registerLink" (click)="closeModel()"></sa-signup-btn>
            </div>
        </div>
    </div>
</div>
      
      