export class UrlUtils {
  public static generateUrlParameters(params: { [key: string]: string }): string {
    return Object.keys(params).length
      ? Object.keys(params)
        .reduce((stringDataArray, key) => {
          const value = params[key];

          return [
            ...stringDataArray,
            `${key}=${value}`
          ];
        }, [])
        .join('&')
      : null;
  }

  public static extractUrlParamsFromExistingUrl(url: string): { [key: string]: string } {
    const urlParamIndex = url.indexOf('?');

    if (urlParamIndex !== -1) {
      return url.substring(urlParamIndex + 1)
        .split('&')
        .reduce((previousValue, currentValue) => {
          const keyValue = currentValue.split('=');
          return {
            ...previousValue,
            [keyValue[0]]: keyValue[1]
          };
        }, {});
    }

    return {};
  }

  public static removeQueryParameters(url: string): string {
    const index = url.indexOf('?');
    return index === -1
      ? url
      : url.substr(0, index);
  }

  public static isExternal(url: string): boolean {

    const pat = /^https?:\/\//i;
    return pat.test(url);
  }

  public static generateClassNameFromDomainName(url: string): string {
    if (url) {
      const domainExtractRegex = /^(?:https?:\/\/)?(?:www\.)?([^.]+)/i;
      const regexMatchData = url.match(domainExtractRegex);

      return regexMatchData[regexMatchData.length - 1]; // Get last capture group
    }

    return '';
  }


  /**
   * URL encode the parameters and then apply base64 encode.
   */
  public static encode(path: string): string {
    return btoa(encodeURIComponent(path));
  }

  /**
   * Base64 decode the parameters, URL decode and then split by Pipeline.
   */
  public static decode(path: string): string[] {
    return decodeURIComponent(atob(path))
      .split('|');
  }
}
