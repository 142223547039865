import {HttpClient} from '@angular/common/http';
import {Injectable, isDevMode} from '@angular/core';
import {Observable, timer, of, BehaviorSubject} from 'rxjs';
import {AmgFixtureIsLive, AmgFixtureMediaData} from '../models/fixture/amg.fixture';

// @dynamic
@Injectable()
export class FixtureService {
  private cacheLiveEntry: { [entryIdMediaType: string]: BehaviorSubject<boolean> };

  constructor(private httpClient: HttpClient) {
    this.cacheLiveEntry = {};
  }

  public isLiveApiCall(entry: AmgFixtureMediaData, refresh?: boolean): Observable<boolean> {
    if (entry && entry.isLiveUrl) {
      if (!this.cacheLiveEntry[entry.isLiveUrl] || refresh) {
        if (!this.cacheLiveEntry[entry.isLiveUrl]) {
          this.cacheLiveEntry[entry.isLiveUrl] = new BehaviorSubject<boolean>(false);
        }

        this.httpClient.get<AmgFixtureIsLive>(entry.isLiveUrl).subscribe(data => {
          if (data) {
            this.cacheLiveEntry[entry.isLiveUrl].next(data.isLive);

            if (isDevMode()) {
              const isLiveString = data.isLive ? 'live' : 'not live';
              const pollingDurationInSeconds = data.pollingFrequency * 1000;

              console.log(
                `Polling success. '${entry?.entryId}' is currently ${isLiveString}, and will poll in ${pollingDurationInSeconds} seconds.`);
            }

            if (data.pollingFrequency) {
              timer(data.pollingFrequency * 1000).subscribe(() => {
                this.isLiveApiCall(entry, true);
              });
            }
          }
        });
      }

      return this.cacheLiveEntry[entry.isLiveUrl].asObservable();
    }

    return of(false);
  }
}
