import {WpImage} from '../models/wordpress/wp-image';

export class WordpressUtils {


  public static getImageData(image: WpImage): { src: string; srcset: string; } {
    return {
      src: image.sizes.large.url,
      srcset: WordpressUtils.generateSrcSet(image)
    };
  }

  private static generateSrcSet(image: WpImage): string {
    const thumbImage = `${image.sizes.thumbnail.url} ${image.sizes.thumbnail.width}w`;
    const mediumImage = `${image.sizes.medium.url} ${image.sizes.medium.width}w`;
    const largeImage = `${image.sizes.large.url} ${image.sizes.large.width}w`;

    const originalImage = `${image.url} ${image.width}w`;

    return `${thumbImage}, ${mediumImage}, ${largeImage}, ${originalImage}`;
  }


  public static getValueByPath(wpPath: string, pageData): string {

    const wpPathArray = wpPath.split('/');
    let data = pageData;

    wpPathArray.forEach((item) => {

      data = data[item];
    });

    return data;
  }
}
