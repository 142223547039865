<header>
  <div class="container-menu">
    <div id="menu-content">
      <!-- Mobile Header -->
      <app-header-mobile
        (loginPanelClick)="setIsMyAccountPanelOpen($event)"
        (searchModal)="openSearchModal($event)"
        (toggleleMenu)="menuToggle($event)"
        [firstName]="firstName"
        [isLoggedIn]="isLoggedIn"
        [isMyAccountPanelOpen]="isMyAccountPanelOpen"
        [liveItem]="liveItem"
        buttonOpen="true"
      ></app-header-mobile>
      <nav [ngClass]="{'show': toggleMenu === true }" class="collapse navbar-collapse">
        <app-header-mobile
          (click)="menuToggle(false)"
          (loginPanelClick)="setIsMyAccountPanelOpen($event)"
          (searchModal)="openSearchModal($event)"
          (toggleleMenu)="menuToggle($event)"
          [firstName]="firstName"
          [isLoggedIn]="isLoggedIn"
          [isMyAccountPanelOpen]="isMyAccountPanelOpen"
          [liveItem]="liveItem"
          buttonClose="true"
        ></app-header-mobile>
        <div class="logo">
          <a routerLink="/home"></a>
        </div>

        <div class="menu-container">
          <div *ngFor="let item of menuData; let i = index"
               [class.selected]="item === selectedItem" [ngClass]="'menu' + i"
               class="menu">

            <ng-container *ngIf="isExternal(item.link); else internal">
              <div class="link-btn">
                <a (click)="menuToggle(false)" [attr.href]="item.link" class="external" target="_blank">
                  <span [textContent]="item.label"></span>
                </a>
              </div>
            </ng-container>

            <ng-template #internal>
              <div class="link-btn">
                <ng-container *ngIf="item.submenu; else nosubmenu">
                  <a (click)="openSubmenu($event, item)"
                     [ngClass]="{ 'selected': isSubMenuSelected(item.link) }"
                     class="top-menu no-click">

                    <span [textContent]="item.label" class="text"></span>
                    <span class="caret"></span>
                  </a>
                </ng-container>
                <ng-template #nosubmenu>
                  <a (click)="menuToggle(false)"
                     [ngClass]="{'live': !!item.isLive}"
                     [routerLink]="item.link"
                     class="top-menu"
                     routerLinkActive="selected">
                    <span [textContent]="item.label"></span>
                  </a>
                </ng-template>
              </div>
              <div *ngIf="item.submenu" class="submenu">
                <div class="content-box">
                  <div *ngFor="let subitem of item.submenu">
                    <h2>{{subitem.single_menu.title}}</h2>
                    <ul>
                      <li (click)="menuToggle(false)" *ngFor="let sublink of subitem.single_menu.sub_link;">
                        <a [routerLink]="generateRouterLink(item, subitem, sublink)"
                           [textContent]="sublink.label"
                           routerLinkActive="selected"></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>

        <div [ngClass]="{'active': isLoggedIn}" class="user-component navbar-component">

          <div *ngIf="commonData?.oefb_link" class="oefb-link">
            <a [href]="commonData.oefb_link" target="_blank"></a>
          </div>

          <div *ngIf="commonData?.newsletter_link" class="newsletter-link">
            <a [href]="commonData.newsletter_link" target="_blank" title="Newsletter"></a>
          </div>
          <div>
            <a [routerLink]="['/oefbthek', {'search': 1}]" class="search-form" (click)="menuToggle(false)">
              <svg height="20.003" viewBox="0 0 20 20.003" width="20" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.728,17.294,15.833,13.4a.937.937,0,0,0-.664-.273h-.637a8.122,8.122,0,1,0-1.406,1.406v.637a.937.937,0,0,0,.273.664l3.895,3.895a.934.934,0,0,0,1.324,0l1.106-1.106A.942.942,0,0,0,19.728,17.294Zm-11.6-4.168a5,5,0,1,1,5-5A5,5,0,0,1,8.126,13.126Z"
                  fill="#fff"
                  id="search-solid"/>
              </svg>
            </a>
          </div>
          <div>
            <app-sso-button (elementClick)="setIsMyAccountPanelOpen($event)"
                            [firstName]="firstName"
                            [isLoggedIn]="isLoggedIn"
                            [isMyAccountPanelOpen]="isMyAccountPanelOpen">
            </app-sso-button>
          </div>

        </div>
      </nav>
    </div>
  </div>
</header>


