import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import {FooterComponent} from './core/components/footer/footer.component';
import {HeaderComponent} from './core/components/header/header.component';
import {HeaderMobileComponent} from './core/components/header/header-mobile/header-mobile.component';
import {Back2topLibModule, ComponentsLibModule, SearchLibModule, UserAuthLibModule} from 'components-lib';
import {LazyLoadImageModule} from 'ng-lazyload-image';

import {SharedModule} from './shared/shared.module';
import {SsoLoginModule} from './modules/sso-login/sso-login.module';
import {SsoButtonComponent} from './core/components/header/sso-button/sso-button.component';

import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {ShareIconsModule} from 'ngx-sharebuttons/icons';
import {SHARE_BUTTONS_CONFIG, ShareButtonsConfig, SharerMethod} from 'ngx-sharebuttons';

registerLocaleData(localeDe);
const shareButtonsConfig: ShareButtonsConfig = {
  include: ['facebook', 'twitter', 'linkedin', 'reddit', 'whatsapp', 'email', 'copy'],
  exclude: [],
  gaTracking: true,
  autoSetMeta: false,
  twitterAccount: 'oefb1904',
  sharerMethod: SharerMethod.Window
}


@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    HeaderMobileComponent,
    SsoButtonComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ComponentsLibModule.forRoot(),
    BrowserAnimationsModule,
    LazyLoadImageModule,
    SearchLibModule,
    Back2topLibModule,
    UserAuthLibModule,
    SharedModule,
    SsoLoginModule,
    ShareIconsModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'de'},
    {
      provide: SHARE_BUTTONS_CONFIG,
      useValue: shareButtonsConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
