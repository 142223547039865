<div #videoContainer class="video-container" [ngSwitch]="layoutCard">
  <ng-container *ngSwitchCase="'cardVideo'">
    <div *ngFor="let item of videoList" class="video">
      <sa-card-video
        [formatDate]="formatDate"
        [className]='className'
        [isCategory]='isCategory'
        [isRuntime]='isRuntime'
        [isDateTimeImage]='isDateTimeImage'
        [isDateTime]='isDateTime'
        [isEntitlement]='isEntitlement'
        [componentData]="item"
        [playlistPath]="path"
        [getMetaData]="getMetaData"
        [subTitle]="item.metaData.competition"
        [isSubTitle]="true">
      </sa-card-video>
    </div>
  </ng-container>
</div>
