import { Pipe, PipeTransform } from '@angular/core';
import {formatDate} from '@angular/common';

@Pipe({
  name: 'dateFormatted'
})
export class DateFormattedPipe implements PipeTransform {

  transform(date: string, format: string, locale?: string): string {

    return date ? formatDate(date, format, locale || 'en') : null;
  }

}
