import { Component, OnInit, ChangeDetectorRef, Input, EventEmitter, Output } from '@angular/core';
import {Subscription} from 'rxjs';
import {ModalService} from '../../../services/modal.service';

@Component({
  selector: 'sa-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss']
})
export class SearchModalComponent implements OnInit {

  private modalSubscription: Subscription;
  @Input() isOpened:boolean;
  @Input() fieldvalue:string;
  @Input() placeholder:string;
  @Input() searchLabel:string;
  @Input() iconSearchColor:string;
  @Output() onSearch = new EventEmitter<any>();

  constructor(private modalService: ModalService, private cd: ChangeDetectorRef) { 
    this.isOpened = false;
  }

  ngOnInit(): void {

    this.modalSubscription = this.modalService.$openSearchModalObservable.subscribe((event) => {
      if (event === 'open-modal') {
        this.isOpened = true;
        this.cd.markForCheck();
      }
    });
  }

  closeModel() {
    this.isOpened = false;
    this.cd.markForCheck();
  }

  onValueSearch(term){
    this.isOpened = !this.isOpened;
    this.onSearch.emit(term);
  }

  destroy(): void {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }

}
