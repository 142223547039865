import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TruncatePipe } from './truncate.pipe';
import { DateFormattedPipe } from './date-formatted.pipe';
import { SecondsDurationPipe } from './duration-format.pipe';

@NgModule({
  declarations: [ 
    TruncatePipe,
    DateFormattedPipe,
    SecondsDurationPipe
    ],
  imports: [
    RouterModule,
    CommonModule
  ],
  exports: [
    TruncatePipe,
    DateFormattedPipe,
    SecondsDurationPipe
  ]
})
export class PipeModule {
 
}
