export interface VideoPlaylist {
    key?: string;
    title?: string;
    feed: string;
    active?: boolean;
    path?: string;
  }
  
  export enum VideoEntitlementEnum {
    'paid' = 'subscribed',
    '*' = 'registered',
    '?' = 'watch now'
  }