<div class="content">
  <ul>
    <li *ngFor="let item of itemList">
      <a *ngIf="isSidebarTitle" [href]="item.link">{{item.title}}</a>
      <a *ngIf="isSidebarImage" [href]="item.link">
        <img [src]="item.image" alt="{{item.title}}"/>
      </a>
    </li>
  </ul>
</div>
<div class="button">
  <button (click)="toggleSidebar()">{{nameButton}}</button>
</div>
