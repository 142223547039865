import { UrlUtils } from './url-utils';
import {
  AnywhereData, AnywhereItemData,
  AnywherePagingData,
  AnywhereResponse,
  AnywhereSectionData
} from '../models/anywhere/anywhere-api.type';
import {CardPlaylistData, Playlist} from '../models/playlist/playlist';


export class AnywhereUtils {
    public static isMoreDataAvailable(pagingData: AnywherePagingData): boolean {
        return ((pagingData.pageSize * pagingData.pageIndex) + pagingData.itemCount) < pagingData.totalCount;
    }

    public static addSearchEndpointToAnywhereUrl(urlWithoutParams: string): string {
        const searchIdx = urlWithoutParams.lastIndexOf('/search');

        return searchIdx === -1
            ? `${urlWithoutParams}/search`
            : urlWithoutParams;
    }

    public static extractAnywhereData<T>(data: AnywhereResponse<T>): AnywhereData<T> {
        if ((data as AnywhereSectionData<T>).sections) {
            return (data as AnywhereSectionData<T>).sections[0];
        } else if ((data as AnywhereData<T>).itemData) {
            return (data as AnywhereData<T>);
        }

        return null;
    }

    public static generateAnywhereUrl(
        anywhereUrl: string,
        urlParameters?: { [key: string]: string },
        defaultPageSize?: string,
        pageIndex?: number) {

        const urlParamsObject = {
            ...UrlUtils.extractUrlParamsFromExistingUrl(anywhereUrl),

            ...(urlParameters ? urlParameters : {}),

            pageIndex: pageIndex ? pageIndex.toString() : '0',
            ...(typeof defaultPageSize === 'string' ? {pageSize: defaultPageSize} : {})
        };
        const urlWithoutParams = AnywhereUtils
            .addSearchEndpointToAnywhereUrl(UrlUtils.removeQueryParameters(anywhereUrl));
        const urlParams = UrlUtils.generateUrlParameters(urlParamsObject);

        return `${urlWithoutParams}?${urlParams}`;
    }


    public static mapFlatAnywhereDataToPlaylistData<T>(data: AnywhereData<T>,
                                                       playlistTitle?: string,
                                                       autoPlay?: boolean): Playlist<T> {
        return AnywhereUtils.mapToPlaylistData(data.itemData, data.pagingData, playlistTitle, autoPlay);
    }

    public static generateAnywhereUrlFilteredByEntryId(
        entryId: string,
        generatedAnywhereUrl: string,
        urlParameters?: { [key: string]: string }): string {

        const urlParamsObject = {
            ...UrlUtils.extractUrlParamsFromExistingUrl(generatedAnywhereUrl),
            ...(urlParameters ? urlParameters : {}),

            query: AnywhereUtils.generateAnywhereQueryFilterById(entryId)
        };

        const urlWithoutParams = AnywhereUtils
            .addSearchEndpointToAnywhereUrl(UrlUtils.removeQueryParameters(generatedAnywhereUrl));
        const urlParams = UrlUtils.generateUrlParameters(urlParamsObject);

        return `${urlWithoutParams}?${urlParams}`;
    }

    public static mapToPlaylistData<T>(
        data: AnywhereItemData<T>[],
        pagingData: AnywherePagingData,
        playlistTitle: string,
        autoPlay?: boolean,
        cardRoutePath?: string): Playlist<T> {

        const playList: Playlist<T> = {
            playlist: {
                title: playlistTitle,
                autoPlay: (typeof autoPlay === 'boolean')
                    ? autoPlay
                    : true
            },

            cards: data.map(value => AnywhereUtils.generatePlaylistCard<T>(value, cardRoutePath)),

            anywherePagingData: pagingData
        };

        return playList;
    }

    public static generatePlaylistCard<T>(item: AnywhereItemData<T>, cardRoutePath: string): CardPlaylistData<T> {
        return {
            item,

            ...(!!cardRoutePath
                ? {routePath: AnywhereUtils.generateCardRoutePath<T>(item, cardRoutePath)}
                : {})
        };
    }

    private static generateCardRoutePath<T>(data: AnywhereItemData<T>, cardRoutePath: string): string {
        return `${data.mediaData.entryId}|${cardRoutePath}`;
    }

    private static generateAnywhereQueryFilterById(entryId): string {
        return `(mediaData.entryId:(${entryId}))`;
    }
}
