<ng-container *ngIf="!scriptLoadProperties.isLoading;">
  <ng-container *ngIf="!scriptLoadProperties.error; else errorMessageTemplate">
    <div class="player-embed"
         [id]="defaultPlayerId"
         #playerEmbedElement>
    </div>
  </ng-container>
</ng-container>

<div [hidden]="!(scriptLoadProperties.isLoading || isPlayerLoading)"
     class="player-loader"
     [ngClass]="{
      'script-load': scriptLoadProperties.isLoading,
      'player-load': isPlayerLoading
     }">
  <ng-content></ng-content>
</div>

<ng-template #errorMessageTemplate>
  <div class="player-message script-error player-error-message">
     <span *ngIf="scriptLoadProperties.error.scriptLoadError"
            [innerHTML]="videoPlayerProperties.scriptLoadErrorMessage">
     </span>
  </div>
</ng-template>
