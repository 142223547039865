import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public subjectModal = new Subject<string>();
  public subjectLoginModal = new Subject<string>();
  public $openLoginModalObservable: Observable<string> = this.subjectLoginModal.asObservable();
  public $openSearchModalObservable: Observable<string> = this.subjectModal.asObservable();

  constructor() { }

  public openLoginModal(): void {
    this.subjectLoginModal.next('open-modal');
  }

  public openModal(): void {
    this.subjectModal.next('open-modal');
  }
}
