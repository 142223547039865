
import { Pipe, PipeTransform } from '@angular/core';
import {DateTimeUtils} from '../date-time-utils';
@Pipe({
    name: 'secondsDuration'
  })
  export class SecondsDurationPipe implements PipeTransform {

    transform(totalSeconds: number): string {

      return  DateTimeUtils.generateRuntimeBySeconds(totalSeconds);
    }

}
