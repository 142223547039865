import { Component, OnInit, Input } from '@angular/core';
import {PlayerUtils} from '../../../utils/player-utils';
import {VideoEntitlementEnum} from '../../../models/playlist/video.type';
import {GetMetaData} from '../../../models/cards/cards.type';

@Component({
  selector: 'sa-card-horizontal',
  templateUrl: './card-horizontal.component.html',
  styleUrls: ['./card-horizontal.component.scss']
})
export class CardHorizontalComponent implements OnInit {
  defaultImage = '/assets/images/placeholder.jpg';
  entitlementKey: string;

  @Input() title: string;
  @Input() description: string;
  @Input() buttonName: string;
  @Input() runtime: string;
  @Input() routerLink: string;
  @Input() linkImg: string;
  @Input() thumbnail: string;
  @Input() entitlement: string;
  @Input() date: string;
  @Input() truncateText: number;

  @Input() isTitle:boolean;
  @Input() isDescription:boolean;
  @Input() isEntitlement:boolean;
  @Input() isRuntime:boolean;
  @Input() componentData: any;
  @Input() playlistPath: string;

  @Input() getMetaData: GetMetaData<unknown>;


  constructor() { }

  ngOnInit(): void {
    if (this.componentData) {
      this.routerLink = `/video/${this.generatePath()}`;
      this.entitlement = VideoEntitlementEnum['*'];
      this.entitlementKey = '*';

      if (this.getMetaData) {
        const data = this.getMetaData(this.componentData);

        if (data.title) {
          this.title = data.title;
        }

        if (data.entitlements) {
          this.entitlement = data.entitlements.value;
          this.entitlementKey = data.entitlements.key;
        }

        if (data.runtime) {
          this.runtime = data.runtime;
        }

        if (data.description) {
          this.description = data.description;
        }
      }

      if(this.componentData.mediaData.thumbnailUrl.length > 0)
        this.linkImg = this.thumbnail = `${this.componentData.mediaData.thumbnailUrl}width/1000`;
      else
        this.linkImg = this.defaultImage;
    }
  }

  private generatePath(): string {
    return PlayerUtils.encodeUrlParamsForPlayer(`${this.componentData.mediaData.entryId}|${this.playlistPath}`);
  }

}
